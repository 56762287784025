import * as React from "react";
import {FC, InputHTMLAttributes} from "react";
import parse from "html-react-parser";
import {IFormFieldProps} from "../../IFormFieldProps";
import Element from "../../../../domain/Element/Element";
import Input from "../input/Input";
import RangeSlider from "../range-slider/RangeSlider";
import MagicCheckbox from "../checkbox/MagicCheckbox";
import PercentageInputRange from "./PercentageInputRange";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    element: Element
    percentageElements: (Element | undefined)[] | undefined
    formData: Record<string, unknown> | []
    defaultValue: string | number | undefined
    clearErrors: (id: string | undefined) => void
    register: (() => void) | undefined
    readonly?: boolean
}

const PercentageInputRangeGroup: FC<IProps> = (
    {
        element,
        percentageElements,
        formData,
        defaultValue,
        register,
        clearErrors,
        error,
        readonly
    }
) => {
    const attributes = element?.attributes

    return (
        <>
            <div className={element.cssClasses + ` group-percentage-select-range`} title={attributes?.help}>
                <div className={`group-percentage-select-range-label`}>
                    <label>{parse(attributes?.label ?? '')}</label>
                </div>
                <div className={`group-percentage-select-range-content`}>
                    {
                        percentageElements?.map(
                            (childElement) => (
                                childElement &&
                                <PercentageInputRange
                                    element={childElement}
                                    formData={formData}
                                    error={error}
                                    register={register}
                                    readonly={readonly}
                                />
                            )
                        )
                    }
                </div>
                <div>
                    <Input
                        classes={'u-hide'}
                        register={register}
                        clearErrors={clearErrors}
                        type={'number'}
                        error={error}
                        id={element.id}
                        name={element.name}
                        required={attributes?.required}
                        readonly={readonly || attributes?.readonly}
                        defaultValue={defaultValue}
                    />
                </div>
            </div>
        </>
    )
}

export default PercentageInputRangeGroup