import ReceiptGatewayInterface from '../../domain/Receipt/ReceiptGatewayInterface';
import Receipt from '../../domain/Receipt/Receipt';

export default class FindAllReceiptsUseCase
{
  public receiptGateway;

  constructor(ReceiptGateway: ReceiptGatewayInterface) {
    this.receiptGateway = ReceiptGateway;
  }

  execute(): Receipt[]|null {
    return this.receiptGateway.find()
  }
}
