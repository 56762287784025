import {FC} from 'react'
import parse from 'html-react-parser';

interface IFieldErrorMessageProps {
  message: string
}

const FieldErrorMessage: FC<IFieldErrorMessageProps> = ({message}) => {
  return (
    <span className={'help u-txt-color-error'}>{parse(message)}</span>
  )
};

export default FieldErrorMessage;
