import {ViewBlockInterface} from './ViewBlock'

interface ViewPageInterface {
  id: string;
  name: string;
  blocks: ViewBlockInterface[];
}

class ViewPage {
  constructor(
    public id: string,
    public name: string,
    public blocks: ViewBlockInterface[],
  ) {
    this.id = id;
    this.name = name;
    this.blocks = blocks;
  }
}

export {
  ViewPage
}

export type {
  ViewPageInterface
}
