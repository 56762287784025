import Receipt from '../../domain/Receipt/Receipt';

export default class LocalStorageGateway {
  save(datas: Array<string>): Promise<boolean> {
    return new Promise((resolve) => {

      const values = {}
      datas.map(data => {
        values[data['element_id']] = data['value']
      })

      localStorage.setItem('form', JSON.stringify(values))

      resolve(true)
    })
  }

  saveDirect(direct: boolean): Promise<boolean> {
    return new Promise((resolve) => {

      localStorage.setItem('direct', String(direct))

      resolve(true)
    })
  }

  saveReceipts(receipts: Receipt[]|null): Promise<boolean> {
    return new Promise((resolve) => {

      localStorage.setItem('receipts', JSON.stringify(receipts))

      resolve(true)
    })
  }

  saveClientId(clientId: number|null): Promise<boolean> {
    return new Promise((resolve) => {

      localStorage.setItem('client_id', String(clientId))

      resolve(true)
    })
  }

  saveAuthorizedClients(clientsId: number[]): Promise<boolean> {
    return new Promise((resolve) => {

      localStorage.setItem('authorized_clients', JSON.stringify(clientsId))

      resolve(true)
    })
  }
}
