import React from 'react';

import Router from './route/Router';

import './assets/styles/main.scss'
import ScrollToTop from './presentation/component/button/ScrollToTop'

const App: React.FC = () => {
  return <>
    <Router/>
    <ScrollToTop />
  </>
};

export default App;
