import FooterGatewayInterface from '../../domain/Footer/FooterGatewayInterface'
import {Footer, Block, FooterInterface} from '../../domain/Footer/Footer'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../presentation/util/envVariable'

type FooterFromAPI = {
  blocks: { extraInfo: string }[]
}

export default class FetchFooterGateway implements FooterGatewayInterface {
  async retrieve(): Promise<FooterInterface | null> {
    try {
      const datas: FooterFromAPI = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/footer`, {})

      return new Footer(
        datas.blocks.map((block) => {
          return new Block(
            block.extraInfo,
          )
        })
      )
    } catch {
      return null
    }
  }
}
