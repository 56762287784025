import FormGatewayInterface from './FormGatewayInterface';

export default class SaveUseCase
{
    public formGateway: FormGatewayInterface;

    constructor(FormGateway: FormGatewayInterface) {
        this.formGateway = FormGateway;
    }

    execute(data: Array<string>|Record<string, string>): Promise<unknown> {
        return this.formGateway.save(data)
    }
}
