import {Folder} from './Folder';

export default class FolderAndPageId {
  folder: Folder
  pageId: string
  url: string

  constructor(folder: Folder, pageId: string, url: string) {
    this.folder = folder
    this.pageId = pageId
    this.url = url
  }
}
