import React, {FunctionComponent} from 'react';

const Passport: FunctionComponent = () => {

  return (
    <svg width="66" height="87" viewBox="0 0 66 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="18.7485" y="0.433476" width="47" height="70" rx="7.5" transform="rotate(7.13495 18.7485 0.433476)" fill="white"/>
      <rect x="18.7485" y="0.433476" width="47" height="70" rx="7.5" transform="rotate(7.13495 18.7485 0.433476)" fill="#119DA4" fillOpacity="0.1"/>
      <rect x="18.7485" y="0.433476" width="47" height="70" rx="7.5" transform="rotate(7.13495 18.7485 0.433476)" stroke="#119DA4"/>
      <rect x="1.71875" y="12.5813" width="50" height="73" rx="9" stroke="white" strokeWidth="2"/>
      <rect x="3.71875" y="14.5813" width="46" height="69" rx="7" fill="white"/>
      <rect x="3.71875" y="14.5813" width="46" height="69" rx="7" fill="#119DA4" fillOpacity="0.1"/>
      <rect x="3.71875" y="14.5813" width="46" height="69" rx="7" stroke="#119DA4" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.7188 35.5813C16.7188 30.0613 21.1887 25.5813 26.7087 25.5813C32.2388 25.5813 36.7188 30.0613 36.7188 35.5813C36.7188 41.1013 32.2388 45.5813 26.7087 45.5813C21.1887 45.5813 16.7188 41.1013 16.7188 35.5813ZM30.6888 31.5813H33.6388C32.6788 29.9313 31.1488 28.6513 29.3088 28.0213C29.9088 29.1313 30.3688 30.3313 30.6888 31.5813ZM26.7188 27.6213C27.5487 28.8213 28.1988 30.1513 28.6287 31.5813H24.8088C25.2388 30.1513 25.8888 28.8213 26.7188 27.6213ZM18.7188 35.5813C18.7188 36.2713 18.8187 36.9413 18.9787 37.5813H22.3588C22.2787 36.9213 22.2188 36.2613 22.2188 35.5813C22.2188 34.9013 22.2787 34.2413 22.3588 33.5813H18.9787C18.8187 34.2213 18.7188 34.8913 18.7188 35.5813ZM19.7987 39.5813H22.7487C23.0687 40.8313 23.5287 42.0313 24.1287 43.1413C22.2887 42.5113 20.7587 41.2413 19.7987 39.5813ZM19.7987 31.5813H22.7487C23.0687 30.3313 23.5287 29.1313 24.1287 28.0213C22.2887 28.6513 20.7587 29.9213 19.7987 31.5813ZM26.7188 43.5413C25.8888 42.3413 25.2388 41.0113 24.8088 39.5813H28.6287C28.1988 41.0113 27.5487 42.3413 26.7188 43.5413ZM24.2188 35.5813C24.2188 36.2613 24.2888 36.9213 24.3787 37.5813H29.0588C29.1488 36.9213 29.2188 36.2613 29.2188 35.5813C29.2188 34.9013 29.1488 34.2313 29.0588 33.5813H24.3787C24.2888 34.2313 24.2188 34.9013 24.2188 35.5813ZM29.3088 43.1413C29.9088 42.0313 30.3688 40.8313 30.6888 39.5813H33.6388C32.6788 41.2313 31.1488 42.5113 29.3088 43.1413ZM31.2188 35.5813C31.2188 36.2613 31.1587 36.9213 31.0787 37.5813H34.4587C34.6187 36.9413 34.7188 36.2713 34.7188 35.5813C34.7188 34.8913 34.6187 34.2213 34.4587 33.5813H31.0787C31.1587 34.2413 31.2188 34.9013 31.2188 35.5813Z" fill="#119DA4"/>
      <rect x="13.7188" y="67.5813" width="26" height="2" rx="1" fill="#119DA4"/>
    </svg>
  );
}

export default Passport;
