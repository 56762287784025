import React, {FunctionComponent} from 'react';

const NotAllowed: FunctionComponent = () => {

  return (
    <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 3C6.5 3 2 7.5 2 13C2 18.5 6.5 23 12 23C17.5 23 22 18.5 22 13C22 7.5 17.5 3 12 3ZM4 13C4 8.6 7.6 5 12 5C13.8 5 15.5 5.6 16.9 6.7L5.7 17.9C4.6 16.5 4 14.8 4 13ZM7.1 19.3C8.5 20.4 10.2 21 12 21C16.4 21 20 17.4 20 13C20 11.2 19.4 9.5 18.3 8.1L7.1 19.3Z"
              fill="white" />
      </g>
    </svg>
  );
}

export default NotAllowed;
