import Element from "../Element";

export default class OrderElementPreview {

    public static presentGroup(
        element: Element,
        submittedData: Record<string, any>,
        elements: Element[] | null
    ): string {
        const orderElementsIds = element.attributes?.childrenElementsIds ?? [];
        const presentationString = 'empty' !== element.attributes?.presentation
            ? `${element.attributes?.presentation}`
            : '';
        const orderElementsPresentation:Array<string> = []
        orderElementsIds.forEach(
            (orderElementId) => {
                const order = submittedData[orderElementId]
                if (undefined !== order) {
                    orderElementsPresentation[Number(order)] = OrderElementPreview.presentElement(
                        Number(order),
                        orderElementId,
                        elements
                    )
                }
            }
        )
        return `
            <div class="flex-container u-mtm">
                <div class="col-md-10 u-px0 u-my0 b-bottom">
                    <span class="u-txt-size-xl u-mxs">&#9759;</span>
                    <span class="">${presentationString}</span>
                </div>
                <div class="col-md-12">
                    <div>${orderElementsPresentation.join('')}</div>
                </div>
            </div>
        `;
    }

    public static presentElement(
        order: number,
        orderElementId: string,
        elements: Element[] | null
    ): string {
        const orderElement = elements?.find(anElement => anElement.id === orderElementId);
        const orderElementPresentation = orderElement?.attributes?.presentation;
        return `
            <div>
                <span class="u-mxs">&#8627;</span>
                <span>
                    <strong>
                        <u class="u-txt-color-ink-1">${order}</u>
                    </strong>
                </span>
                <span class="u-txt-size-xl u-mxs">&#9755;</span>
                <span>${orderElementPresentation}</span>
            </div>
        `;
    }

}
