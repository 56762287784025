import {CustomerInfoRecallInterface} from '../../domain/CustomerInfoRecall/CustomerInfoRecall'
import CustomerInfoRecallGatewayInterface from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'
import {PotentialCustomerInterface} from '../../domain/PotentialCustomer/PotentialCustomer'

export type RetrieveCustomerInfoRecallCommand = {
  dossierId: string
  scope: string
}

export default class RetrieveCustomerInfoRecallUseCase
{
  constructor(private readonly customerInfoRecallGateway: CustomerInfoRecallGatewayInterface) {}

  async handle(retrieveCustomerInfoRecallCommand: RetrieveCustomerInfoRecallCommand): Promise<{
    customerInfoRecall: CustomerInfoRecallInterface,
    potentialCustomerList: PotentialCustomerInterface[]
  } | null> {
    const response = await this.customerInfoRecallGateway.retrieve(retrieveCustomerInfoRecallCommand.dossierId)
    if (response) {
      return response
    }
    return null
  }
}
