import React, {FunctionComponent} from 'react';

import '../../../assets/styles/components/_page-loader.scss'
import {useTranslation} from "react-i18next";

type Props = {
    description?: string | null
}

const PageLoader: FunctionComponent<Props> = ({description}) => {
    const {t} = useTranslation()

    return (
        <>
            <div className="content-page-loader">
                <div className="page-loader"></div>
                <div className='loader-text'>
                    {
                        description  ?? t('button.download-in-progress')
                    }
                </div>
            </div>
            <div className="page-loader-backdrop fade show"></div>
        </>
    );
}

export default PageLoader;
