import React, {FunctionComponent} from 'react';
import envVariable from '../../util/envVariable'
const icon = envVariable('REACT_APP_ICON_SIGNATURE')

type Props = {
  color: string
}

const SignatureFiles: FunctionComponent<Props> = ({color}) => {
  if (icon === 'signature-1') {
    return (
      <svg viewBox="0 0 70.24 65.15" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35.99,64.98c17.87-1.88,30.87-17.94,28.99-35.8-.11-1.09-.29-2.2-.51-3.31-.15-.74-.88-1.23-1.63-1.07s-1.23.88-1.07,1.63c.21,1.04.37,2.04.48,3.05,1.72,16.35-10.19,31.06-26.54,32.78-16.35,1.72-31.06-10.19-32.78-26.54C1.21,19.35,13.12,4.64,29.47,2.92c5.67-.6,11.36.43,16.43,2.97.68.33,1.5.06,1.84-.62.34-.68.06-1.51-.62-1.84C41.58.66,35.37-.47,29.17.18,11.3,2.06-1.7,18.12.18,35.98s17.94,30.87,35.8,28.99h.01Z"
          strokeWidth={0}
          fill={color}/>
        <path
          d="M64.16.01c-1.63,0-3.16.64-4.31,1.78l-30.23,30.24c-.67.67-1.18,1.49-1.47,2.38l-3.2,9.6c-.12.37-.03.78.25,1.05.2.2.46.3.73.3.11,0,.22-.02.32-.05l9.6-3.2c.9-.3,1.71-.81,2.38-1.47l30.24-30.24c1.15-1.15,1.78-2.68,1.78-4.31s-.64-3.16-1.78-4.31c-1.15-1.15-2.68-1.78-4.31-1.78h.01ZM36.77,39.19c-.44.44-.98.78-1.58.97l-7.65,2.55,2.55-7.65c.2-.59.53-1.14.97-1.58l24.86-24.86,5.7,5.7-24.86,24.86h0ZM63.09,12.87l-5.7-5.7.68-.68,5.7,5.7-.68.68ZM67.01,8.95l-1.78,1.78-5.7-5.7,1.78-1.78c.76-.76,1.77-1.18,2.85-1.18s2.09.42,2.85,1.18c.76.76,1.18,1.77,1.18,2.85s-.42,2.09-1.18,2.85h0Z"
          strokeWidth={0}
          fill={color}/>
      </svg>
    )
  }

  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.86746 56.0557L9.86463 56.0529C-2.88821 43.3 -2.88821 22.535 9.86463 9.78213C15.9127 3.73411 24.251 0.3 32.9175 0.3C41.5835 0.3 49.9204 3.7337 55.9675 9.9443L55.9704 9.94713C68.7232 22.7 68.7232 43.465 55.9704 56.2179C49.9224 62.2659 41.584 65.7 32.9175 65.7C24.2515 65.7 15.9146 62.2663 9.86746 56.0557ZM11.7489 54.3306L11.7488 54.3307L11.7536 54.3353C17.4176 59.8327 24.9168 63 32.9175 63C40.9187 63 48.4195 59.8322 54.0846 54.1671C65.7526 42.4991 65.7505 23.5003 54.0861 11.6694L54.0862 11.6693L54.0814 11.6647C48.4174 6.16728 40.9182 3 32.9175 3C24.9163 3 17.4155 6.16776 11.7504 11.8329C0.0823885 23.5008 0.0845358 42.4997 11.7489 54.3306ZM29.2404 40.6371L29.4671 40.8639L29.6783 40.6226L45.8415 22.1503C46.4255 21.5718 47.2173 21.4891 47.7204 21.9921C48.2521 22.5238 48.2843 23.3429 47.8536 24.0782L30.6144 43.7801C30.5809 43.7913 30.5495 43.806 30.5202 43.8235C30.4634 43.8576 30.4206 43.9 30.4007 43.92H30.3182L30.2304 44.0079L30.0654 44.1729C30.0494 44.1889 30.0011 44.2163 29.8982 44.2335C29.8021 44.2495 29.7017 44.25 29.6175 44.25C29.5069 44.25 29.4239 44.2237 29.337 44.189C29.3268 44.1849 29.3141 44.1796 29.3 44.1738C29.2647 44.1592 29.2208 44.141 29.1846 44.1282C29.1836 44.1265 29.1825 44.1249 29.1813 44.1232C29.1307 44.0473 29.0654 44.0004 29.0092 43.9723C28.9541 43.9448 28.9027 43.9325 28.8676 43.9267C28.8493 43.9236 28.8335 43.922 28.821 43.9211C28.8181 43.9209 28.8154 43.9207 28.8128 43.9206C28.8098 43.9204 28.8069 43.9203 28.8043 43.9202L28.7978 43.92L28.795 43.92L28.7937 43.92H28.7931H28.7928C28.7927 43.92 28.7927 43.92 28.7926 43.9333C28.7926 43.9576 28.7925 44.0262 28.7925 44.22V43.92C28.7761 43.92 28.7635 43.92 28.753 43.9199C28.7354 43.9035 28.712 43.8803 28.6746 43.8429L18.1146 33.2829C17.5718 32.74 17.5718 31.94 18.1146 31.3971C18.6575 30.8543 19.4575 30.8543 20.0004 31.3971L29.2404 40.6371Z" fill={color} stroke="white" strokeWidth="0.6"/>
    </svg>
  );
}

export default SignatureFiles;
