import FolderGatewayInterface from "../../domain/Folder/FolderGatewayInterface";
import storage from "../../presentation/util/storage";
import LocalStorageGateway from '../../gateway/Form/LocalStorageGateway';
import {Folder} from '../../domain/Folder/Folder';

export default class CreateUseCase
{
  public folderGatewayInterface;

  constructor(FileGatewayInterface: FolderGatewayInterface) {
    this.folderGatewayInterface = FileGatewayInterface;
  }

  execute(formId: string, clientId: number|null): Promise<string> {
    return this.folderGatewayInterface.createFolder(formId, clientId)
      .then((folder) => {
        new LocalStorageGateway().save(folder.values)
        new LocalStorageGateway().saveDirect(folder.direct ? folder.direct : false)
        new LocalStorageGateway().saveClientId(null)
        new LocalStorageGateway().saveReceipts([])
        new LocalStorageGateway().saveAuthorizedClients([])
        storage.setFolderId(folder.id);
        storage.setFormId(folder.form_id)
        return folder.id;
      });
  }
}
