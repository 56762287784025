import Converter from './Converter'

export const downloadFile = (file: { title: string; }, dataBase64: string) => {
  const blob = new Converter().dataURItoBlob(dataBase64)
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = file.title;
  a.click();
  window.URL.revokeObjectURL(url);
}
