import CustomerInfoRecallGatewayInterface from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'
import {CustomerInfoRecall, CustomerInfoRecallInterface} from '../../domain/CustomerInfoRecall/CustomerInfoRecall'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../presentation/util/envVariable'
import dayjs from 'dayjs'
import {PotentialCustomer, PotentialCustomerInterface} from '../../domain/PotentialCustomer/PotentialCustomer'

interface PotentialCustomerData {
  civility: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  birthDay?: { date: string };
  birthCity: string;
  birthCountry: string;
  isMoralPerson: boolean;
  company: string;
  companyManager: string;
  legalForm: string;
  managerBirthDate?: { date: string };
  managerBirthCity: string;
  managerBirthCountry: string;
}

export default class CustomerInfoRecallGateway implements CustomerInfoRecallGatewayInterface {
  async retrieve(dossierId: string): Promise<{customerInfoRecall: CustomerInfoRecallInterface, potentialCustomerList: PotentialCustomer[]} | null> {
    try {
      const datas = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/pairing/customer`, {})

      return {
        customerInfoRecall: new CustomerInfoRecall(
          datas.customerInfoRecall.civility,
          datas.customerInfoRecall.firstName,
          datas.customerInfoRecall.lastName,
          datas.customerInfoRecall.email,
          (datas.customerInfoRecall.birthDay) ? dayjs(datas.customerInfoRecall.birthDay.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
          datas.customerInfoRecall.birthCity,
          datas.customerInfoRecall.birthCountry,
          datas.customerInfoRecall.isMoralPerson,
          datas.customerInfoRecall.company,
          datas.customerInfoRecall.companyManager,
          datas.customerInfoRecall.legalForm,
          (datas.customerInfoRecall.managerBirthDate) ? dayjs(datas.customerInfoRecall.managerBirthDate.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
          datas.customerInfoRecall.managerBirthCity,
          datas.customerInfoRecall.managerBirthCountry
        ),
        potentialCustomerList: datas.potentialCustomerList.map((potentialCustomer: PotentialCustomerData) => {
          return new PotentialCustomer(
            potentialCustomer.civility,
            potentialCustomer.firstName,
            potentialCustomer.lastName,
            potentialCustomer.username,
            potentialCustomer.email,
            potentialCustomer.birthDay ? dayjs(potentialCustomer.birthDay.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            potentialCustomer.birthCity,
            potentialCustomer.birthCountry,
            potentialCustomer.isMoralPerson,
            potentialCustomer.company,
            potentialCustomer.companyManager,
            potentialCustomer.legalForm,
            potentialCustomer.managerBirthDate ? dayjs(potentialCustomer.managerBirthDate.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
            potentialCustomer.managerBirthCity,
            potentialCustomer.managerBirthCountry
          );
        })
      }
    } catch {
      return null
    }
  }

  async pair(dossierId: string, username: string|null, scope: string): Promise<boolean> {
    try {
      await Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/pairing/customer`, {code: username, scope: scope})
      return true
    } catch {
      return false
    }
  }

  async search(username: string): Promise<PotentialCustomerInterface|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/customer/${username}`, {})
    .then((response) => {
      if (response) {
        return new PotentialCustomer(
          response.customer.civility,
          response.customer.firstName,
          response.customer.lastName,
          response.customer.username,
          response.customer.email,
          response.customer.birthDay ? dayjs(response.customer.birthDay.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
          response.customer.birthCity,
          response.customer.birthCountry,
          response.customer.isMoralPerson,
          response.customer.company,
          response.customer.companyManager,
          response.customer.legalForm,
          response.customer.managerBirthDate ? dayjs(response.customer.managerBirthDate.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
          response.customer.managerBirthCity,
          response.customer.managerBirthCountry
        );
      } else {
        return null;
      }
    })
    .catch(() => {
      return null
    })
  }
}
