import React, { ReactElement, ReactNode, useState } from 'react'

interface TabProps {
  label: string
  children: ReactNode
}

const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>
}

interface TabsProps {
  classes: string
  children: ReactNode
}

const Tabs: React.FC<TabsProps> = ({ classes, children }) => {
  const [activeTab, setActiveTab] = useState(0)

  const tabArray = React.Children.toArray(children).filter(
      (child): child is ReactElement<TabProps> =>
        React.isValidElement(child) && 'label' in child.props
    )

  return (
    <div className={classes}>
      <div className="tabs flex-container header-step-button-container">
        {tabArray.map((child, index) => (
          <div key={child.key} className={`col-md-3`}>
            <button key={index} onClick={() => setActiveTab(index)}
                    className={`button button-tab button--width-full ${index === activeTab ? 'button-tab--active' : ''}`}>
              {(child as ReactElement<TabProps>).props.label}
            </button>
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabArray[activeTab]}
      </div>
    </div>
  )
}

const TabsLikeWizard: React.FC<TabsProps> = ({ classes, children }) => {
  const [activeTab, setActiveTab] = useState(0)

  const tabArray = React.Children.toArray(children).filter(
    (child): child is ReactElement<TabProps> =>
      React.isValidElement(child) && 'label' in child.props
  )

  return (
    <div className={classes}>
      <div className="tabs wizzard-inner u-pointer">
        {tabArray.map((child, index) => (
          <div key={`tab-wizzard-${index}`} className={`wizzard__step wizzard__step--no-mt ${index === activeTab ? 'wizzard__step--active' : ''}`}
               onClick={() => setActiveTab(index)}>
            <div className="step__progression">
              <div className="progress"/>
            </div>
            <div className="step__number">{index + 1 < 10 ? `0${index + 1}` : index + 1}</div>
            <div className="step__title">{(child as ReactElement<TabProps>).props.label}</div>
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabArray[activeTab]}
      </div>
    </div>
  )
}

export {
  Tab,
  Tabs,
  TabsLikeWizard,
}
