
import Select from 'react-select'
import {useTranslation} from 'react-i18next';
import storage from '../../util/storage'
import { FC } from 'react';

export interface ISelectFormProps {
  onSelectChange: (formId: string|null) => void;
}

const SelectForm: FC<ISelectFormProps> = ({onSelectChange}) => {
  const {t} = useTranslation()

  const formOptions = [
    {value: 'all', label: 'Toutes les souscriptions'},
    {value: storage.getClientFormId(), label: 'Souscriptions en ligne'},
    {value: storage.getBackOfficeFormId(), label: 'Souscriptions manuelles'},
  ];

  return (
    <Select
      menuPosition="absolute"
      menuPortalTarget={document.body}
      placeholder={t('common.select-placeholder')}
      options={formOptions}
      defaultValue={formOptions[0]}
      isMulti={false}
      isClearable={false}
      onChange={val => {
        onSelectChange(val.value !== 'all' ? val.value : null)
      }}
    />
  )
};

export default SelectForm;
