import React, {FunctionComponent, ReactNode} from 'react';

import Cloud from '../../svg/Cloud';
import Button from '../../button/Button';
import {useTranslation} from 'react-i18next';

interface IProps {
  icon?: ReactNode
  iconLabel?: string
}

const Layout: FunctionComponent<IProps> = (icon) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="icon-wrapper">
        {icon.icon}
        <div className="icon__content">
          <span className="u-mxs">{icon.iconLabel}</span> <br/>
        </div>
      </div>
      <div className="drag-and-drop-wrapper u-txt-center">
        <Cloud/>
        <br/>
        <span>{t('common.drag-file-here')}</span>
      </div>
      <div className="browse-wrapper">
        <Button classes="button button-primary--outline button--width-fit u-pys u-pxs" label="Parcourir"/>
      </div>
    </>
  )
}

export default Layout;
