import IntegrationStateHistoryGatewayInterface from '../../domain/IntegrationStateHistory/IntegrationStateHistoryGatewayInterface'
import {IntegrationStateHistoryInterface} from '../../domain/IntegrationStateHistory/IntegrationStateHistory'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../presentation/util/envVariable'

export default class IntegrationStateHistoryGateway implements IntegrationStateHistoryGatewayInterface {
  async retrieveList(dossierId: string): Promise<IntegrationStateHistoryInterface[] | null> {
    try {
      return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/history`, {})
    } catch {
      return null
    }
  }
}
