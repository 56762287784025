import DocusignInterface from './DocusignInterface';

export default class DocusignUrl implements DocusignInterface{
  docusign: { url:string }

  constructor(docusign) {
    this.docusign = docusign
  }

}
