export enum EFolderStatus {

    Ongoing                                         = 'ongoing',
    Registered                                      = 'registered',
    Registration                                    = 'registration',
    Additional_documents                            = 'additional_documents',

    Compliant                                       = 'compliant',
    Compliance_validation                           = 'compliance_validation',
    Compliant_payment                               = 'compliant_payment',

    Signature                                       = 'signature',
    Signed                                          = 'signed',
    Unsigned                                        = 'unsigned',
    Canceled                                        = 'canceled',
    Rejected                                        = 'rejected',

    Mission_validation                              = 'mission_validation',
    Mission_validated                               = 'mission_validated',
    Mission_abort                                   = 'mission_abort',

    Mission_signed_by_subscriber                    = 'mission_signed_by_subscriber',
    Mission_signed_by_co_subscriber                 = 'mission_signed_by_co_subscriber',
    Mission_signed_by_subscriber_spouse             = 'mission_signed_by_subscriber_spouse',
    Mission_signed_by_subscriber_advisor            = 'mission_signed_by_subscriber_advisor',

    Mission_sent_to_subscriber                      = 'mission_sent_to_subscriber',
    Mission_sent_to_co_subscriber                   = 'mission_sent_to_co_subscriber',
    Mission_sent_to_subscriber_spouse               = 'mission_sent_to_subscriber_spouse',
    Mission_sent_to_subscriber_advisor              = 'mission_sent_to_subscriber_advisor',

    Mission_delivered_to_subscriber                 = 'mission_delivered_to_subscriber',
    Mission_delivered_to_co_subscriber              = 'mission_delivered_to_co_subscriber',
    Mission_delivered_to_subscriber_spouse          = 'mission_delivered_to_subscriber_spouse',
    Mission_delivered_to_subscriber_advisor         = 'mission_delivered_to_subscriber_advisor',

    Mission_declined_by_subscriber                  = 'mission_declined_by_subscriber',
    Mission_declined_by_co_subscriber               = 'mission_declined_by_co_subscriber',
    Mission_declined_by_subscriber_spouse           = 'mission_declined_by_subscriber_spouse',
    Mission_declined_by_advisor                     = 'mission_declined_by_advisor',

    Adequacy_report_validated                       = 'adequacy_report_validated',
    Adequacy_report_validation                      = 'adequacy_report_validation',
    Adequacy_report_failure                         = 'adequacy_report_failure',

    Subscription_sent_to_subscriber                 = 'subscription_sent_to_subscriber',
    Subscription_sent_to_co_subscriber              = 'subscription_sent_to_co_subscriber',
    Subscription_sent_to_subscriber_spouse          = 'subscription_sent_to_subscriber_spouse',
    Subscription_sent_to_subscriber_advisor         = 'subscription_sent_to_subscriber_advisor',

    Subscription_delivered_to_subscriber            = 'subscription_delivered_to_subscriber',
    Subscription_delivered_to_co_subscriber         = 'subscription_delivered_to_co_subscriber',
    Subscription_delivered_to_subscriber_spouse     = 'subscription_delivered_to_subscriber_spouse',
    Subscription_delivered_to_subscriber_advisor    = 'subscription_delivered_to_subscriber_advisor',

    Subscription_signed_by_subscriber               = 'subscription_signed_by_subscriber',
    Subscription_signed_by_co_subscriber            = 'subscription_signed_by_co_subscriber',
    Subscription_signed_by_subscriber_spouse        = 'subscription_signed_by_subscriber_spouse',
    Subscription_signed_by_subscriber_advisor       = 'subscription_signed_by_subscriber_advisor',

    Subscription_declined_by_subscriber             = 'subscription_declined_by_subscriber',
    Subscription_declined_by_co_subscriber          = 'subscription_declined_by_co_subscriber',
    Subscription_declined_by_subscriber_spouse      = 'subscription_declined_by_subscriber_spouse',
    Subscription_declined_by_advisor                = 'subscription_declined_by_advisor',
}
