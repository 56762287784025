import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import '../../assets/styles/components/_svg_animation.scss'
import FetchFolderGateway from "../../gateway/Folder/FetchFolderGateway";
import {EDocusignButton} from "../../domain/Docusign/EDocusignButton";
import {EFolderStatus} from "../../domain/Folder/EFolderStatus";
import ErrorOrSuccess from "../component/docusign/ErrorOrSuccess";
import Loader from "../component/loader/Loader";

const DocusignCallback: FunctionComponent = (): any => {
    const [dossierSigned, setDossierSigned] = useState<boolean | null>(null)
    const [intervalIsRunning, setIntervalIsRunning] = useState<boolean>(true)
    const [timeLimitReached, setTimeLimitReached] = useState<boolean>(false)

    const idBtnClickedDocusign: string | null = localStorage.getItem("id-btn-docusign")
    const folderId = localStorage.getItem("folder_id")
    const statusRef = useRef(null)

    useEffect(() => {
        if (idBtnClickedDocusign && folderId) {
            const timeoutFetchFolderStatus = setInterval(() => {
                fetchFolder()?.then((dossier: any) => {
                    if (dossier.status && statusRef.current) {
                        if (statusRef.current !== dossier.status) {
                            clearInterval(timeoutFetchFolderStatus)
                            setIntervalIsRunning(false)
                            setDossierSigned(alreadySigned(dossier.status))
                        }
                    } else if (dossier.status) {
                        statusRef.current = dossier.status
                    }
                })
            }, 3000)

            const timeoutLimitAnswer = setTimeout(() => {
                setTimeLimitReached(true)
                clearInterval(timeoutFetchFolderStatus)
            }, 25000)

            return () => {
                clearTimeout(timeoutLimitAnswer)
                clearInterval(timeoutFetchFolderStatus)
            }
        }
    }, [])

    const fetchFolder = () => {
        if (folderId) {
            return new FetchFolderGateway().getFolder(folderId).then((response) => {
                return response?.dossier
            })
        }
    }

    const alreadySigned = (dossierStatus: string): boolean => {

        if (dossierStatus === EFolderStatus.Signed || dossierStatus === EFolderStatus.Mission_validated) {
            return true
        }
        switch (idBtnClickedDocusign) {
            case EDocusignButton.MISSION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return dossierStatus.includes(EFolderStatus.Mission_signed_by_subscriber)
            case EDocusignButton.MISSION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return dossierStatus.includes(EFolderStatus.Mission_signed_by_co_subscriber)
            case EDocusignButton.MISSION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                return dossierStatus.includes(EFolderStatus.Mission_signed_by_subscriber_spouse)
            case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return dossierStatus.includes(EFolderStatus.Subscription_signed_by_subscriber)
            case EDocusignButton.SUBSCRIPTION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return dossierStatus.includes(EFolderStatus.Subscription_signed_by_co_subscriber)
            case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                return dossierStatus.includes(EFolderStatus.Subscription_signed_by_subscriber_spouse)
            case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_ADVISOR_SIGN_BUTTON_ELEMENT_ID:
                return dossierStatus.includes(EFolderStatus.Subscription_signed_by_subscriber_advisor)
            default:
                return true
        }
    }

    if (intervalIsRunning && !timeLimitReached) {
        return <Loader/>
    } else {
        if (dossierSigned) {
            localStorage.setItem("id-btn-docusign", "success")
            return <ErrorOrSuccess state={"success"}/>
        } else {
            localStorage.setItem("id-btn-docusign", "error")
            return <ErrorOrSuccess state={"error"}/>
        }
    }
}

export default DocusignCallback;
