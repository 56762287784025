import React, {FunctionComponent} from 'react';

const Cloud: FunctionComponent = () => {

  return (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <path fillRule="evenodd" clipRule="evenodd" d="M24 8.79065C31.28 8.79065 37.34 13.9706 38.7 20.8706C43.9 21.2306 48 25.5106 48 30.7906C48 36.3106 43.52 40.7906 38 40.7906H12C5.38 40.7906 0 35.4106 0 28.7906C0 22.6106 4.68 17.5106 10.7 16.8706C13.2 12.0706 18.22 8.79065 24 8.79065ZM28 34.7907V26.7907H34L24.72 17.4907C24.32 17.0907 23.7 17.0907 23.3 17.4907L14 26.7907H20V34.7907H28Z" fill="#BDC0CA"/>
      </g>
    </svg>
  );
}

export default Cloud;
