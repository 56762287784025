import {createSlice} from '@reduxjs/toolkit'

// Define a type for the slice state
interface elements {
    currentElements: string | null
    currentElementsWithCondition: string | null
    currentElementsWithCalcule: string | null
    currentElementsWithReference: string | null
}

// Define the initial state using that type
const initialState: elements = {
    currentElements: null,
    currentElementsWithCondition: null,
    currentElementsWithCalcule: null,
    currentElementsWithReference: null,
}

export const elementsSlice = createSlice(
    {
        name: 'elements',
        initialState: initialState,
        reducers: {
            setCurrent: (
                state,
                action
            ) => {
                return {
                    currentElements: action.payload.currentElements,
                    currentElementsWithCondition: action.payload.currentElementsWithCondition,
                    currentElementsWithCalcule: action.payload.currentElementsWithCalcule,
                    currentElementsWithReference: action.payload.currentElementsWithReference
                }
            },
            updateElementTimestamp: (
                state,
                action
            ) => {
                if (state.currentElements) {
                    const currentState = JSON.parse(state.currentElements)
                    const currentIndexForElementId = currentState.findIndex(
                        (storedElement) => storedElement.id === action.payload.id
                    )

                    if (currentState[currentIndexForElementId]) {
                        currentState[currentIndexForElementId].timestamp = new Date().getTime()
                        state.currentElements = JSON.stringify(currentState)
                    }
                }
            }
        }
    }
)

export const {setCurrent, updateElementTimestamp} = elementsSlice.actions

export default elementsSlice.reducer
