import envVariable from "./presentation/util/envVariable";

type TrackerProps = {
  eventCode?: string,
  eventName: string
};
  
export const gtmTracker = ({ eventCode, eventName }: TrackerProps) => {
  const gtmId = envVariable('REACT_APP_GTM_ID')
  if ('' !== gtmId && '' !== eventName && window["gtag"]) {
    window["gtag"]('event', eventCode ?? 'finalisation de dossier', {'send_to': `${gtmId}/${eventName}`})
  }
};