import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import '../../../assets/styles/components/_modal.scss';
import FieldErrorMessage from '../elements/messages/FieldErrorMessage';

type Props = {
  onConfirm: (data: any) => void;
  onClose: () => void;
  isRequired?: boolean;
  value?: string;
  message: string;
  placeholder?: string;
  optionStyles?: Modal.Styles;
  errorMessage?: string|null;
  captionYes?:string;
  captionNo?:string;
};

const InputModalWithControl: FunctionComponent<Props> = ({ onConfirm, onClose, isRequired, value, message, placeholder, optionStyles, errorMessage, captionYes, captionNo }) => {
  Modal.setAppElement('#root');

  const { t } = useTranslation();
  const [inputValue, setinputValue] = useState<string>(value || '');
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)

  useEffect(() => {
    if (isRequired)
      setIsConfirmDisabled(inputValue === '');
  }, [inputValue, isRequired])


  const onClick = () => {
    onConfirm(inputValue);
  };

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      minWidth: "40%",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
    },
  };

  const modalStyles = useMemo(() => {
    if (optionStyles) {
      return {
        ...styles,
        ...optionStyles,
      };
    }
    return styles;
  }, [optionStyles]);

  return (
    <>
      <Modal isOpen={true} style={modalStyles}>
        <div className="content-small">
          <p className="content-small__text u-pbb">
            {message}
          </p>
          <div className="flex-container">
            <div className="col-12">
              <div className="form-control__input u-mbs">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setinputValue(e.target.value)}
                  placeholder={placeholder || ''}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      onClick()
                    }
                  }}
                />
              </div>
              <div className="form-control__input u-mbs">
                {errorMessage && <FieldErrorMessage message={errorMessage}/>}
              </div>
            </div>
          </div>
          <div className="content__buttons">
            <button
                type="button"
                className="button button-error button--large"
                onClick={onClose}
              >
                {captionNo || t('modal.confirmation.no')}
              </button>
              <button
                type="button"
                className="button button-primary button--large"
                onClick={onClick}
                disabled={isConfirmDisabled}
              >
              {captionYes || t('modal.confirmation.yes')}
            </button>
            </div>
          </div>
      </Modal>
    </>
);
};

export default InputModalWithControl;
