import ErrorInterface from './ErrorInterface';

export default class Error implements ErrorInterface {
  property: string
  message: string

  constructor(property:string, message:string) {
    this.property = property
    this.message = message
  }
}
