import {CustomerInfoRecallInterface} from '../../domain/CustomerInfoRecall/CustomerInfoRecall'
import CustomerInfoRecallGatewayInterface from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'
import {PotentialCustomerInterface} from '../../domain/PotentialCustomer/PotentialCustomer'

export default class SearchCustomerInfoRecallUseCase
{
  constructor(private readonly customerInfoRecallGateway: CustomerInfoRecallGatewayInterface) {}

  async handle(username: string): Promise<PotentialCustomerInterface|null> {
    const response = await this.customerInfoRecallGateway.search(username)
    if (response) {
      return response
    }
    return null
  }
}
