import IntegrationStateHistoryGatewayInterface from '../../domain/IntegrationStateHistory/IntegrationStateHistoryGatewayInterface'
import {IntegrationStateHistoryInterface} from '../../domain/IntegrationStateHistory/IntegrationStateHistory'

export type GetIntegrationStateHistoryCommand = {
  dossierId: string
}

export default class GetIntegrationStateHistoryUseCase
{
  constructor(private readonly integrationStateHistoryGateway: IntegrationStateHistoryGatewayInterface) {}

  async handle(getIntegrationStateHistoryCommand: GetIntegrationStateHistoryCommand): Promise<IntegrationStateHistoryInterface[] | null> {
    const response = await this.integrationStateHistoryGateway.retrieveList(getIntegrationStateHistoryCommand.dossierId)
    if (response) {
      return response
    }
    return null
  }
}
