import React, {FunctionComponent} from 'react';

const Electricity: FunctionComponent = () => {

  return (
    <svg width="49" height="72" viewBox="0 0 49 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.71875" y="1.5813" width="46" height="69" rx="7" fill="white"/>
      <rect x="1.71875" y="1.5813" width="46" height="69" rx="7" fill="#119DA4" fillOpacity="0.1"/>
      <rect x="1.71875" y="1.5813" width="46" height="69" rx="7" stroke="#119DA4" strokeWidth="2"/>
      <path d="M20.3711 14.4661V23.4661C20.3711 24.0161 20.8211 24.4661 21.3711 24.4661H23.3711V31.6161C23.3711 32.1261 24.0411 32.3061 24.3011 31.8661L29.4911 22.9661C29.8811 22.2961 29.4011 21.4661 28.6311 21.4661H26.3711L28.8611 14.8161C29.1111 14.1661 28.6311 13.4661 27.9311 13.4661H21.3711C20.8211 13.4661 20.3711 13.9161 20.3711 14.4661Z" fill="#119DA4"/>
      <rect x="11.7188" y="54.5813" width="26" height="2" rx="1" fill="#119DA4"/>
      <rect x="11.7188" y="46.5813" width="26" height="2" rx="1" fill="#119DA4"/>
    </svg>
  );
}

export default Electricity;
