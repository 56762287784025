import FormGatewayInterface from '../../domain/Form/FormGatewayInterface';
import Element from '../../domain/Element/Element';
import ElementPreviewPresenter from '../../domain/Element/ElementPreviewPresenter';
import ElementPreview from '../../domain/Element/ElementPreview';

export default class GetPresentation {
    public formGateway;

    constructor(FormGateway: FormGatewayInterface) {
        this.formGateway = FormGateway;
    }

    /**
     * Return a Preview Element Object for Element
     * @param element
     */
    execute(
        element: Element
    ): ElementPreview | null {

        const values = this.formGateway.getCurrentValueForFormId().values

        const elementPreviewPresenter = new ElementPreviewPresenter().execute(
            element,
            element.reference,
            values,
            []
        )

        if (null !== elementPreviewPresenter) {
            return elementPreviewPresenter
        }

        return null
    }
}
