import React, {FunctionComponent} from 'react';

type Props = {
  color: string,
};

const Back: FunctionComponent<Props> = ({color}) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2912 7.00502H4.12124L9.00124 2.12502C9.39124 1.73502 9.39124 1.09502 9.00124 0.705022C8.81441 0.51777 8.56076 0.412537 8.29624 0.412537C8.03172 0.412537 7.77807 0.51777 7.59124 0.705022L1.00124 7.29502C0.61124 7.68502 0.61124 8.31502 1.00124 8.70502L7.59124 15.295C7.98124 15.685 8.61124 15.685 9.00124 15.295C9.39124 14.905 9.39124 14.275 9.00124 13.885L4.12124 9.00502H15.2912C15.8412 9.00502 16.2912 8.55502 16.2912 8.00502C16.2912 7.45502 15.8412 7.00502 15.2912 7.00502Z"
        fill={color} />
    </svg>
  );
}

export default Back;
