interface FooterInterface {
  blocks: BlockInterface[]
}

interface BlockInterface {
  extraInfo: string|null
}

class Block implements BlockInterface {
  constructor(
    public extraInfo: string|null,
  ) {}
}

class Footer implements FooterInterface {
  constructor(
    public blocks: BlockInterface[],
  ) {}
}

export {Footer, Block}
export type {FooterInterface, BlockInterface}
