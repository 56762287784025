import React, {FunctionComponent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {confirmAlert} from 'react-confirm-alert'

import envVariable from '../../util/envVariable'
import Back from '../svg/Back'
import Envelope from '../svg/Envelope'
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway'
import ConfirmationModalWithReason from '../modal/ConfirmationModelWithReason'
import Button from '../button/Button'
import ButtonDropdown from '../button/ButtonDropdown'

type Props = {
  dossierId: string
}

const HeaderManageComponent: FunctionComponent<Props> = ({dossierId}) => {
  const {t} = useTranslation()
  const logo = `${envVariable('REACT_APP_API_HOST')}/theme/images/logo.png`;
  const [state, setState] = useState<string | null>(null)

  useEffect(() => {
    new FetchFolderGateway().retrieveState(dossierId).then((response) => {
      setState(response)
    })
  }, [dossierId])

  const handleValidate = () => {
    (new FetchFolderGateway()).updateDossierIntegrationState(dossierId, 'validated', null).then(response => {
      if (response) {
        window.location.href = `/manage-dossier/${dossierId}`
      }
    })
  }

  const confirmHandleRefused = () => {
    confirmAlert({
      customUI: ({onClose}) => {
        return <ConfirmationModalWithReason onConfirm={(message) => handleRefused(message)} onClose={onClose}/>
      },
    })
  }

  const handleRefused = (reason: string | null) => {
    (new FetchFolderGateway()).updateDossierIntegrationState(dossierId, 'refused', reason).then(response => {
      if (response) {
        window.location.href = `/manage-dossier/${dossierId}`
      }
    })
  }

  const confirmHandleError = () => {
    confirmAlert({
      customUI: ({onClose}) => {
        return <ConfirmationModalWithReason onConfirm={(message) => handleError(message)} onClose={onClose}/>
      },
    })
  }

  const handleError = (reason: string | null) => {
    (new FetchFolderGateway()).updateDossierIntegrationState(dossierId, 'error', reason).then(response => {
      if (response) {
        window.location.href = `/manage-dossier/${dossierId}`
      }
    })
  }

  const actions = [
    { label: t('validation.decline'), classes: `dropdown-button__error u-txt-uppercase u-pys`, onClick: () => confirmHandleRefused() },
    { label: t('validation.error-state'), classes: `dropdown-button__warning u-txt-uppercase u-pys`, onClick: () => confirmHandleError() },
    { label: t('validation.accept'), classes: `dropdown-button__success u-txt-uppercase u-pys`, onClick: () => handleValidate() }
  ];

  return (
    <div className="main-header main-header--validation-rcci u-mxl">
      <div className="flex-container middle-xs no-compensation">
        <h1 className="u-mb0 u-mrl">
          <a href="#">
            <img src={logo} alt="Société d'investissement" style={{maxWidth: '150px'}}/>
          </a>
        </h1>
        <Link to="/tableau-de-bord" className="back">
          <Back color="var(--ink-1)" />
          <span className="u-txt-size-l u-txt-color-ink-1 u-mxs">{t('button.back-home')}</span>
        </Link>
      </div>
      <div className="flex-container middle-xs left-xs">
        <h4 className={`u-mb0`}>
        </h4>
      </div>
      <div className="flex-container middle-xs center-xs">
        { ("ongoing" === state || "error" === state) && <ButtonDropdown label={t('validation.rcci')} actions={actions} /> }
      </div>
      <div className="flex-container middle-xs center-xs">
        {/*
        <button type="button" className="button button-secondary button--medium button--width-fit u-pxm">
          {t('common.contact-my-advisor')}
          <span className="right">
            <Envelope />
          </span>
        </button>
        */}
      </div>
    </div>
  )
}

export default HeaderManageComponent
