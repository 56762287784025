import DatalistGatewayInterface from '../../domain/Options/DatalistGatewayInterface';
import DatalistOptions from '../../domain/Options/DatalistOptions';

export default class GetChoices
{
  public datalist;

  constructor(DatalistGateway: DatalistGatewayInterface) {
    this.datalist = DatalistGateway;
  }

  async execute(datalistId: string, term: string): Promise<DatalistOptions|null> {
    return await this.datalist.getOptions(datalistId, term).then(response => response)
  }
}
