import Client from "../../domain/Client/Client";
import ClientGatewayInterface from "../../domain/Client/ClientGatewayInterface";
import Caller from "../../domain/Api/Caller";
import envVariable from "../../presentation/util/envVariable";
import {Folder} from '../../domain/Folder/Folder';
import Error from '../../domain/Error/Error';

export default class FetchClientGateway implements ClientGatewayInterface {
  async getClients(): Promise<Client[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/clients`, {})
      .then(data => {

        if (!data) {
          return null
        }

        return (data.clients).map((client) => {
          return new Client(
            client.id,
            client.civility,
            client.first_name,
            client.last_name,
            client.username,
            client.email,
          )
        })
      })
  }

  async authorize(dossierId: string, clientId: number): Promise<Folder|Error[]> {
    const authorizedClient: number[]  = [clientId]

    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossiers/${dossierId}/authorized-clients`, {'clientsId': authorizedClient})
      .then(response => {

        if (response.errors) {
          return (response.errors).map((error) => {
            return new Error(error.property, error.message)
          })
        }

        return new Folder(
          response.dossier.id,
          response.dossier.form_id,
          response.dossier.client_id,
          response.dossier.advistor_id,
          response.dossier.last_submitted_block_id,
          response.dossier.values,
          response.dossier.direct,
          response.dossier.receipts,
          response.dossier.authorized_clients,
        )
      })
  }
}
