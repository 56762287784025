import React, {FunctionComponent, ReactNode, useState} from 'react';
import FetchPrintGateway from '../../../gateway/Print/FetchPrintGateway';
import GetFileToPrint from '../../../useCase/Print/GetFileToPrint';
import Loader from '../loader/Loader';

type Props = {
  classes: string
  label?: string
  defaultValue?: string
  position?: string
  icon?: ReactNode
}

const ButtonPrint: FunctionComponent<Props> = ({ classes,
                                                        label,
                                                        defaultValue,
                                                        position,
                                                        icon
}) => {
  const [isLoading, setLoading] = useState<boolean>(false)

  function handleClick() {
    if (undefined !== defaultValue) {
      setLoading(true)

      new GetFileToPrint(new FetchPrintGateway()).execute(defaultValue).then(response => {
        if (null !== response) {
          const pdfWindow = window.open('')

          if (null !== pdfWindow) {
            pdfWindow.document.write(
              "<body style='margin:0px;padding:0px;overflow:hidden'><iframe width='100%' height='100%' style='border:0' src='data:application/pdf;base64,"+response.print.file+"'></iframe></body>"
            )
          }
        }

        setLoading(false)
      })
    }
  }

  return (<>
    {(undefined !== defaultValue && !isLoading &&
    <button type="button" className={`${classes}`} onClick={handleClick}>
      {position === 'left' && <span className="left">{icon}</span>}
      {label}
      {position === 'right' && <span className="right">{icon}</span>}
      {position === 'alone' && icon}
    </button>
    )}
    {(isLoading &&
      <Loader />
    )}
  </>)
}

export default ButtonPrint
