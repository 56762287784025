import PrintGatewayInterface from '../../domain/Print/PrintGatewayInterface';
import Print from '../../domain/Print/Print';

export default class GetFileToPrint
{
  public printGateway;

  constructor(PrintGateway: PrintGatewayInterface) {
    this.printGateway = PrintGateway;
  }

  async execute(fileId: string): Promise<Print | null> {
    return await this.printGateway.getFile(fileId).then(response => {
      try {
        return response
      } catch (error) {
        return null
      }
    })
  }
}
