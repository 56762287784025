import React from 'react'
import {useTranslation} from 'react-i18next'

import {PotentialCustomerInterface} from '../../../../domain/PotentialCustomer/PotentialCustomer'
import SelectedTickMark from '../../svg/SelectedTickMark';

interface Props {
  potentialCustomer: PotentialCustomerInterface,
  onSelectCustomer: (username: string) => void;
  selectedUsername: string|null
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const PhysicalPersonLineTable: React.FC<Props> = ({potentialCustomer, onSelectCustomer, selectedUsername}) => {
  const {t} = useTranslation()

  const translationKey = potentialCustomer.birthCity ? 'validation.customer-info-recall-birth-date-with-city' : 'validation.customer-info-recall-birth-date-without-city'

  const handleButtonClick = () => {
    onSelectCustomer(potentialCustomer.username ?? '')
  }

  return (
    <>
      <td>
        {potentialCustomer.username}
      </td>
      <td>
        {capitalizeFirstLetter(potentialCustomer.civility ?? '')}
        &nbsp;{capitalizeFirstLetter(potentialCustomer.firstName ?? '')}
        &nbsp;{capitalizeFirstLetter(potentialCustomer.lastName ?? '')}
      </td>
      <td>
        {(potentialCustomer.managerBirthDate || potentialCustomer.managerBirthCity) && t(translationKey, {
          date: potentialCustomer.birthDay,
          city: capitalizeFirstLetter(potentialCustomer.birthCity ?? ''),
          country: capitalizeFirstLetter(potentialCustomer.birthCountry ?? ''),
        })}
      </td>
      <td>
        {potentialCustomer.email}
      </td>
      <td className={"form-control-center"}>
        { potentialCustomer?.username && selectedUsername === potentialCustomer?.username  ? <SelectedTickMark color={"var(--ink-1)" } /> : (
        <button type="button"
                className="button button-primary--outline button--medium form-control-center"
                onClick={handleButtonClick}>
          {t('validation.select-customer')}
        </button>
        )}
      </td>
    </>
  )

}

export default PhysicalPersonLineTable
