import FormGatewayInterface from '../../domain/Form/FormGatewayInterface';
import { roleBackOfficeList, ERole } from '../../domain/User/Erole';
import storage from '../../presentation/util/storage';

export default class FormGateway implements FormGatewayInterface {
    save(data: Array<string>): Promise<unknown> {
        return new Promise((resolve) => {
            const formString = localStorage.getItem('form')
            const formObject = (formString === null) ? {} : JSON.parse(formString)

            Object.assign(formObject, data);
            localStorage.setItem('form', JSON.stringify(formObject))
            resolve(formObject)
        }).then(response => response);
    }

    updateState(state: string): void {
        const userRole = localStorage.getItem('role')
        if (state !== undefined) {
            if (!userRole || !roleBackOfficeList.includes(userRole) || storage.getFormId() === storage.getBackOfficeFormId()) {
                localStorage.setItem('dossier_state', state)
            }
        }
    }

    find(): Promise<null | Record<string, unknown>> {
        return new Promise<null | Record<string, unknown>>((resolve) => {
            const formString = localStorage.getItem('form');
            const formObject = (formString === null) ? {} : JSON.parse(formString);
            localStorage.setItem('form', JSON.stringify(formObject));
            resolve(formObject);
        }).then(response => response);
    }

    getCurrentValueForFormId(): { values: Record<string, unknown>, direct: boolean, dossier_state: string } {
        const formString = localStorage.getItem('form');
        const formObject = (formString === null) ? {} : JSON.parse(formString);

        return {
            'values': formObject,
            'direct': (localStorage.getItem('direct') === 'true'),
            'dossier_state': localStorage.getItem('dossier_state') || ''
        }
    }

    getValidation(): Promise<unknown> {
        return new Promise((resolve => {
            const formString = localStorage.getItem('validate');
            const formObject = (formString === null) ? {} : JSON.parse(formString);
            resolve(Object.entries(formObject))
        })).then(response => response)
    }

    getValueForBlockId(blockId: string): string | null {
        const formString = localStorage.getItem('form');
        const formObject = (formString === null) ? {} : JSON.parse(formString);

        return (formObject[blockId]) ?? null
    }

    getValueForElementId(elementId: string): string|null {
        const formString = localStorage.getItem('form');
        const formObject = (formString === null) ? {} : JSON.parse(formString);

        return '' !== elementId ? formObject[elementId] ?? null : null;
    }
}
