import {FC, useEffect, useState} from 'react'

import Element from '../../../../domain/Element/Element';
import FormGateway from '../../../../gateway/Form/FormGateway';
import GetPresentation from '../../../../useCase/Presentation/GetPresentation';
import parse from "html-react-parser";

interface IProps {
  element: Element
}

const Presentation: FC<IProps> = ({element}) => {
  const [text, setText] = useState<string | null>(null)

  useEffect(() => {
    const elementPreviewPresenter = new GetPresentation(new FormGateway()).execute(element)

    if (null !== elementPreviewPresenter) {
      setText(elementPreviewPresenter.presentation)
    }
  }, [element])

  return (
    <>
      {(text &&
        <div className={element.cssClasses}>
          <p className="u-mb0">{parse(text)}</p>
        </div>
      )}
    </>
  )
};

export default Presentation;
