import React, {FunctionComponent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import HeaderLightComponent from '../component/header/HeaderLightComponent';
import ClientsComponent from '../component/client/ClientsComponent';
import FooterComponent from '../component/footer/FooterComponent';

import Back from '../component/svg/Back';

const CreateFolderClient: FunctionComponent = () => {
  const {t} = useTranslation()
  const history = useHistory()

  const [term, setTerm] = useState<string>('');

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTerm((e.target.value).toLowerCase());
  }

  return <>
    <HeaderLightComponent />
    <div className="wrap u-mxAuto">
      <a href="#" onClick={() => history.goBack()} className="back">
        <Back color="var(--ink-1)" />
        <span className="u-txt-size-s u-txt-color-ink-1 u-mxs">{t('button.back')}</span>
      </a>
      <div className="flex-container u-mtm">
        <div className="col-md-8">
          <h2 className="h3 help-wrapper u-mbs">{t('folder.customer-choice')}</h2>
        </div>
        <div className="col-md-4">
          <input type="text" onChange={e => handleSearchInputChange(e)} style={{boxShadow: `0px 5px 8px rgb(0 0 0 / 10%)`}} placeholder={t('common.search')} className="button-search" />
        </div>
      </div>
    </div>
    <div className="min-height-content-create-folder">
      <div className="box-elevations box-elevations--base box-elevations--max-heigt-initial  box-elevations--mb u-mxAuto">
        <ClientsComponent term={term}/>
      </div>
    </div>
    <FooterComponent />
  </>
}

export default CreateFolderClient;
