import {FC, InputHTMLAttributes} from "react"
import ERadioType from "./ERadioType";
import {ITypesType} from '../dropzone/ITypesType';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    option: ITypesType,
    checked: boolean,
    onChange: (value: string) => void,
    type: ERadioType,
    register?: () => void,
    name: string,
    disabled?: boolean
}

const RadioItem: FC<IProps> = ({option, onChange, type, register, name, disabled, checked}) => {

    function handleChange(value) {
        onChange(value)
    }

    return (
        <div className={type == ERadioType.SIMPLE ? "col-md-4" : "col-md-6"}>
            <label htmlFor={option.id} className={type}>
                <input type="radio" readOnly checked={checked} ref={register} name={name} value={option.id}
                       disabled={disabled} id={option.id}/>
                <span onClick={() => handleChange(option.id)}>{option.label}</span>
            </label>
        </div>
    )

};
export default RadioItem;
