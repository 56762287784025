import React from 'react'
import { useTranslation } from 'react-i18next'

import {ViewPageInterface} from '../../../../domain/ViewDossier/ViewPage'
import PresentationBlock from './PresentationBlock'


interface Props {
  page: ViewPageInterface,
}

const PresentationPage: React.FC<Props> = ({page}) => {
  const {t} = useTranslation()

  return (
    <>
      {page.blocks.map((block) => {
        return <PresentationBlock block={block} key={block.id} />
      })}
    </>
  );
};

export default PresentationPage
