import {FC, FormEvent, InputHTMLAttributes, useState} from 'react'
import ERadioType from "./ERadioType";
import {IFormFieldProps} from "../../IFormFieldProps";
import FieldErrorMessage from "../messages/FieldErrorMessage";
import {ITypesType} from '../dropzone/ITypesType';
import RadioItemType from './RadioItemType';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    options?: ITypesType[],
    id: string,
    defaultValue: string,
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
    handleChangeForParent?: (newValue: string) => void,
    type: ERadioType,
    name: string
}

const RadioListTypes: FC<IProps> = ({options, id,  label, defaultValue, type, register, name, classes, error, handleChangeForParent}) => {

    const [value, setValue] = useState<string|undefined>(defaultValue);

    const handleChange = (newValue: string) => {
        setValue(newValue);

        if (typeof handleChangeForParent !== 'undefined') {
            handleChangeForParent(newValue)
        }
    }

    return (
        <div className={classes}>
            {label &&
            <p className={`u-txt-size-l u-txt-color-text-light  ${type == ERadioType.CIRCLE ? "u-mbm" : "u-mbs"}`}>{label}</p>}

            <div className="flex-container">
                {options?.map(option => {

                    return (
                        <RadioItemType option={option} value={value} register={register} name={id} type={type} id={name}
                                   checked={option.id == value || option.id == defaultValue} key={option.id}
                                   onChange={handleChange}/>
                    )

                })}

                {error?.message && <FieldErrorMessage message={error.message}/>}

            </div>

        </div>
    )
};
export default RadioListTypes;
