import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from "react-router-dom"
import { useQuery } from '../../../infrastructure/hooks'

type Props = {
  itemsPerPage: number
  totalItems: number
};

export const Pagination = ({ itemsPerPage, totalItems }: Props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const query = useQuery()

  const currentPage = parseInt(query.get('currentPage') || '1', 10)
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const getPaginationRange = () => {
    if (totalPages <= 5) {
      return { startPage: 1, endPage: totalPages }
    }

    if (currentPage <= 3) {
      return { startPage: 1, endPage: 5 }
    }

    if (currentPage + 2 >= totalPages) {
      return { startPage: totalPages - 4, endPage: totalPages }
    }

    return {
      startPage: currentPage - 2,
      endPage: currentPage + 2
    }
  }

  const { startPage, endPage } = getPaginationRange()

  const handlePageChange = (page: number) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('currentPage', page.toString())
    history.push({ search: searchParams.toString() })
    window.scrollTo(0, 0)
  };

  return (
    <>
      <div className="pagination">
        <div className="pagination__action-container">
          <button
            className={`pagination__action ${currentPage === 1 ? '' : 'pagination__action--active'}`}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <span aria-hidden="true" className="-mt-1">&larr;</span>{" "}
            {t("common.previous")}
          </button>
        </div>
        <div className="pagination__number">
          {startPage > 1 && (
            <>
              <button className="number__item" onClick={() => handlePageChange(1)}>1</button>
              {startPage > 2 && <div className="dots">...</div>}
            </>
          )}
          {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(page => (
            <button
              key={page}
              className={`number__item ${currentPage === page ? "number__item--active" : ""}`}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </button>
          ))}
          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && <div className="dots">...</div>}
              <button className="number__item" onClick={() => handlePageChange(totalPages)}>
                {totalPages}
              </button>
            </>
          )}
        </div>
        <div className="pagination__action-container">
          <button
            className={`pagination__action ${currentPage === totalPages ? '' : 'pagination__action--active'}`}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {t("common.next")}{" "}
            <span aria-hidden="true" className="-mt-1">&rarr;</span>
          </button>
        </div>
      </div>
      <div className="pagination-showing">
        {t('common.pagination-showing', {
          start: (currentPage - 1) * itemsPerPage + 1,
          end: Math.min(currentPage * itemsPerPage, totalItems),
          totalItems,
        })}
      </div>
    </>
  )
}
