import CustomerInfoRecallGatewayInterface from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'

export type PairCommand = {
  dossierId: string
  username: string|null
  scope: string
}

export default class PairUseCase
{
  constructor(private readonly customerInfoRecallGateway: CustomerInfoRecallGatewayInterface) {}

  async handle(pairCommand: PairCommand): Promise<boolean> {
    const response = await this.customerInfoRecallGateway.pair(pairCommand.dossierId, pairCommand.username, pairCommand.scope)

    if (response) {
      return true
    }

    return false
  }
}
