import {FunctionComponent} from 'react'
import {useTranslation} from 'react-i18next'
import Button from '../../button/Button'
import FieldErrorMessage from '../messages/FieldErrorMessage'
import Notification from '../../notification/Notification'
import {ENotificationType} from '../../notification/ENotificationType'
import Loader from '../../loader/Loader'
import useProspect from './useProspect'

type Props = {
  label: string
}

const ButtonCreateProspect: FunctionComponent<Props> = ({label}) => {
  const {t} = useTranslation()
  const {
    isLoading,
    isVisibleCreateProspect,
    isVisibleAuthorizeClient,
    success,
    errors,
    handleClick,
    readOnlyMode
  } = useProspect()

  const btnClasses = `button button-primary--outline button--medium button--width-fit ${null !== errors && 'button-error--outline'}`

  return (
    <div className="col-md-12">
      {isVisibleCreateProspect && !isLoading &&
        <div className="flex-container middle-xs center-xs">
          <Button
            classes={btnClasses}
            onClick={() => handleClick('create')}
            label={label || t('common.create-prospect')}
            disabled={readOnlyMode}/>
        </div>
      }
      {isVisibleAuthorizeClient && !isLoading &&
        <div className="flex-container middle-xs center-xs">
          <Button
            classes={btnClasses}
            onClick={() => handleClick('authorize')}
            label={t('common.authorize-client')}
            disabled={readOnlyMode}/> <br/>
        </div>
      }
      {(isLoading &&
        <Loader/>
      )}

      {(success !== null &&
        <Notification label={t(success)} type={ENotificationType.success}/>
      )}

      {null !== errors &&
        <div className="u-txt-center">
          {errors.map((error, index) => {
            return (
              <><FieldErrorMessage message={t(`api.error.${error.message}`)} key={index}/> <br/></>
            )
          })}
        </div>
      }
    </div>
  )
}

export default ButtonCreateProspect
