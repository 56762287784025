import EqualInterface from './EqualInterface';

export default class Equal implements EqualInterface
{
  validate(valueToCompare: unknown, secondValue: unknown): boolean {
    if (secondValue instanceof Array) {
      return secondValue?.includes(valueToCompare)
    }

    return valueToCompare === String(secondValue ?? '')
  }
}
