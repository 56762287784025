import ViewDossierGatewayInterface from '../../domain/ViewDossier/ViewDossierGatewayInterface'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../presentation/util/envVariable'
import {ViewBlock} from '../../domain/ViewDossier/ViewBlock'
import {ViewElement} from '../../domain/ViewDossier/ViewElement'
import {ViewPage, ViewPageInterface} from '../../domain/ViewDossier/ViewPage'

interface PageFromApiInterface {
  id: string,
  name: string,
  blocks: BlockFromApiInterface[]
}

interface BlockFromApiInterface {
  id: string,
  name: string,
  elements: ElementFromApiInterface[]
}

interface ElementFromApiInterface {
  elementId: string,
  presentation: string,
  value: string|number|[]|boolean
}

export default class ViewDossierGateway implements ViewDossierGatewayInterface {
  retrieve(dossierId: string): Promise<ViewPageInterface[]|null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/integration-state/${dossierId}/view`, {})
      .then(response => {
        if (!response) {
          return null
        }

        return response.map((page: PageFromApiInterface) => {
          return new ViewPage(
            page.id,
            page.name,
            page.blocks.map((block: BlockFromApiInterface) => {
              return new ViewBlock(
                block.id,
                block.name,
                block.elements.map((element: ElementFromApiInterface) => {
                  return new ViewElement(
                    element.elementId,
                    element.presentation,
                    element.value
                  )
                })
              )
            })
          )
        })
      }).catch(() => {
        return null
      })
  }
}
