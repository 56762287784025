interface ViewElementInterface {
  id: string;
  presentation: string;
  value: string|number|[]|boolean;
}

class ViewElement {
  constructor(
    public id: string,
    public presentation: string,
    public value: string|number|[]|boolean,
  ) {
    this.id = id;
    this.presentation = presentation;
    this.value = value;
  }
}

export {
  ViewElement
}

export type {
  ViewElementInterface
}
