import React, {FunctionComponent, useEffect} from 'react';
import {useTranslation} from "react-i18next";

type Props = {
    state: string
}

const ErrorOrSuccess: FunctionComponent<Props> = ({state}) => {
    const {t} = useTranslation()

    useEffect(() => {
        if (state === "success") {
            window.parent.postMessage('success', '*')
            localStorage.setItem("id-btn-docusign", "success")
        } else {
            window.parent.postMessage('error', '*')
            localStorage.setItem("id-btn-docusign", "error")
        }
    }, [state])

    return (
        <div className="wrap u-mxAuto">
            <div className="flex-container center-xs u-mts">
                <div className="col-md-12">
                    {
                        state === "success"
                        && (
                            <>
                                <div className="ui-success">
                                    <svg viewBox="0 0 87 87" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Group-3" transform="translate(2.000000, 2.000000)">
                                                <circle id="Oval-2" stroke="rgba(165, 220, 134, 0.2)" strokeWidth="4"
                                                        cx="41.5" cy="41.5"
                                                        r="41.5"/>
                                                <circle className="ui-success-circle" id="Oval-2" stroke="var(--success)"
                                                        strokeWidth="4"
                                                        cx="41.5"
                                                        cy="41.5" r="41.5"/>
                                                <polyline className="ui-success-path" id="Path-2" stroke="var(--success)"
                                                          strokeWidth="4"
                                                          points="19 38.8036813 31.1020744 54.8046875 63.299221 28"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h1 className="u-txt-center">{t('docusign.success')}</h1>
                                <p className="u-txt-center">{t('docusign.success-content')}</p>
                            </>
                        )
                    }
                    {
                        state !== "success" && (
                            <>
                                <div className="ui-error">
                                    <svg viewBox="0 0 87 87" xmlns="http://www.w3.org/2000/svg">
                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Group-2" transform="translate(2.000000, 2.000000)">
                                                <circle id="Oval-2" stroke="rgba(252, 191, 191, .5)" strokeWidth="4"
                                                        cx="41.5" cy="41.5"
                                                        r="41.5"/>
                                                <circle className="ui-error-circle" stroke="#F74444" strokeWidth="4"
                                                        cx="41.5" cy="41.5"
                                                        r="41.5"/>
                                                <path className="ui-error-line1" d="M22.244224,22 L60.4279902,60.1837662"
                                                      id="Line"
                                                      stroke="#F74444"
                                                      strokeWidth="3" strokeLinecap="square"/>
                                                <path className="ui-error-line2" d="M60.755776,21 L23.244224,59.8443492"
                                                      id="Line"
                                                      stroke="#F74444"
                                                      strokeWidth="3" strokeLinecap="square"/>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <h1 className="u-txt-center">{t('docusign.warning')}</h1>
                                <p className="u-txt-center">{t('docusign.user-not-signing')}</p>
                                <p className="u-txt-center">
                                    {t('docusign.step-unsigned-documents')}
                                    <br/>
                                    {t('docusign.waiting-for-new-signing-trial')}
                                </p>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default ErrorOrSuccess;
