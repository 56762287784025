import ReceiptGatewayInterface from '../../domain/Receipt/ReceiptGatewayInterface';
import Receipt from '../../domain/Receipt/Receipt';

export default class LocalStorageReceiptGateway implements ReceiptGatewayInterface {
  find(): Receipt[]|null {
    const receiptsString = localStorage.getItem('receipts')

    return (typeof receiptsString === 'string') ? JSON.parse(receiptsString) : null
  }
}
