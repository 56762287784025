import {FC, InputHTMLAttributes, default as React, useState} from 'react'
import {IFormFieldProps} from '../../IFormFieldProps';
import FieldErrorMessage from '../messages/FieldErrorMessage';

// Datepicker
import Flatpickr from 'react-flatpickr'
import {French} from 'flatpickr/dist/l10n/fr';
import 'flatpickr/dist/themes/material_green.css';

// Datepicker Custom
import CalendarInput from '../../svg/CalendarInput'

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id?: string,
  classes: string,
  minDateString?: string,
  maxDateString?: string,
  name: string,
  defaultValue?: string
}

const DatePickerSimple: FC<IProps> = ({
                                        id,
                                        classes,
                                        label,
                                        name,
                                        minDateString,
                                        defaultValue,
                                        maxDateString,
                                        register,
                                        error,
                                        ...rest
                                      }) => {

  const [selectedDate, handleDateChange] = useState(defaultValue);

  const minDate = (minDateString) ? new Date(minDateString) : null;
  const maxDate = (maxDateString) ? new Date(maxDateString) : null;

  return (
    <div className={`${classes}  ${rest.readonly && "readonly-element"}`} title={rest.help}>
      <div className={`datepicker__wrapper ${error && 'error'}`}>
        <input className={'checkbox-toggle'} name={id} ref={register} value={selectedDate} id={name} readOnly />
        <label htmlFor={id}>{label}</label>
        <CalendarInput />
        <Flatpickr
          className="form-control"
          options={{
            minDate: minDate,
            maxDate: maxDate,
            altFormat: 'd/m/Y',
            dateFormat: 'd/m/Y',
            locale: French,
            defaultDate: defaultValue,
            altInput: true,
            allowInput: true,
          }}
          placeholder="jj/mm/AAAA"
          selected={selectedDate}
          onChange={selectedDate => {
            if (undefined !== selectedDate[0]) {
              handleDateChange(selectedDate[0].toLocaleDateString('fr-FR'))
            }
          }}
        />
        {error?.message && <FieldErrorMessage message={error.message} />}
      </div>
    </div>
  )
};

export default DatePickerSimple;
