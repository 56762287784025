import ProspectGatewayInterface from '../../domain/Prospect/ProspectGatewayInterface';
import Client from '../../domain/Client/Client';

export default class Create
{
  public prospectGateway;

  constructor(ProspectGateway: ProspectGatewayInterface) {
    this.prospectGateway = ProspectGateway;
  }

  async execute(dossierId: string): Promise<Client|Error[]> {
    return await this.prospectGateway.create(dossierId).then(response => response)
  }
}
