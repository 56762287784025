import {OperatorDefinition} from './OperatorDefinition';
import Equal from './Equal';
import NotEqual from './NotEqual';
import GreaterThan from './GreaterThan';

export default class OperatorFactory
{
  static instance(operator: string): Equal  {
    switch (operator) {
      case OperatorDefinition.EQUAL:
        return new Equal
      case OperatorDefinition.NOT_EQUAL:
        return new NotEqual
      case OperatorDefinition.GREATER_THAN:
        return new GreaterThan
      default:
        return new Equal
    }
  }
}
