import Element from '../../domain/Element/Element';

/**
 * Returns Elements Ids to render in relation with current Element Id
 * Relation could be as elements values to calculate, conditioned elements to preview, or referenced elements
 * @param elementsWithCondition
 * @param currentElementId
 * @param elementsWithCalcule
 * @param elementsWithReference
 */
const getElementsIdToRender = (
    elementsWithCondition: Element[] | null,
    currentElementId: string,
    elementsWithCalcule: Element[] | null,
    elementsWithReference: Element[] | null
): string[] => {

    const elementsIdToRender: string[] = []

    null !== elementsWithCondition && (
        elementsWithCondition.map(
            element => {
                // 👇️ Check if NOT undefined or null (using loose not equals)
                if (undefined != element.condition) {
                    (element.condition.rules)?.map(
                        (rule) => {
                            rule.input_id === currentElementId && elementsIdToRender.push(element.id)
                        }
                    )
                }
            }
        )
    )

    null !== elementsWithCalcule && (
        elementsWithCalcule.map(
            element => {
                // 👇️ Check if NOT undefined or null nor empty (using loose not equals)
                if (undefined != element.calculate && '' !== element.calculate) {
                    element.calculate.match(/<input_id>(.*?)<\/input_id>/g)?.map(
                        (input) => {
                            let inputId = input.replace(/<\/?input_id>/g, '')
                            inputId = inputId.replace(/_1/g, '')
                            inputId = inputId.replace(/_0/g, '')
                            inputId === currentElementId && elementsIdToRender.push(element.id)
                        }
                    )
                }
            }
        )
    )

    null !== elementsWithReference && (
        elementsWithReference.map(
            element => {
                // 👇️ Check if NOT undefined or null (using loose not equals)
                if (undefined != element.reference) {
                    element.reference === currentElementId && elementsIdToRender.push(element.id)
                }
            }
        )
    )

    return elementsIdToRender
}

export default getElementsIdToRender;
