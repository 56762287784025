export enum EElementType {

    OUTPUT_TEXT = 'output_text',
    INPUT_TEXT = 'input_text',
    INPUT_TEL = 'input_tel',
    INPUT_MAIL = 'input_mail',
    INPUT_IBAN = 'input_iban',
    INPUT_IBAN_CODE = 'input_iban_code',
    INPUT_BIC_CODE = 'input_bic_code',

    INPUT_DATE = 'input_date',
    INPUT_BIRTHDATE = 'input_birthdate',

    INPUT_INTEGER = 'input_integer',
    INPUT_PERCENTAGE_INTEGER = 'input_percentage_integer',
    INPUT_CONVERT_NUMBER_TO_WORD = 'convert_number_to_word',
    INPUT_ORDERED_INTEGER = 'input_ordered_integer',
    GROUP_ORDERED_INTEGERS = 'group_ordered_integers',

    INPUT_RANGE = 'range',
    INPUT_PERCENTAGE_RANGE = 'input_percentage_range',
    GROUP_PERCENTAGE_INPUT_RANGE = 'input_percentage_range_group',

    INPUT_FLOAT = 'input_float',
    INPUT_FLOAT_CURRENCY_AMOUNT = 'input_float_currency_amount',

    INPUT_CHECKBOX = 'input_checkbox',
    INPUT_RADIO = 'input_radio',
    INPUT_RADIO_SIMPLE = 'input_radio_simple',
    TOGGLE = "toggle",
    CHOICE = "choice",
    CHOICE_AUTOCOMPLETE = 'choice_autocomplete',
    ARRAY_CHOICE = 'array_choice',
    DATALIST_CHOICE = 'datalist_choice',
    UNIQUE_PRODUCT_RADIO = 'input_product_radio',

    SELECT = 'select',
    SELECT_ORDERED_INTEGER = 'select_ordered_integer',

    UNIQUE_SELECT_PRODUCT_CHOICE_LIST = 'unique_select_product_choice_list',
    UNIQUE_SELECT_PRODUCT_CHOICE_AUTOCOMPLETE_LIST = 'unique_select_product_choice_autocomplete_list',
    UNIQUE_SELECT_PRODUCT_CHOICE_RADIO = 'unique_select_product_choice_radio',

    OUTPUT_PRODUCT_MINIMUM_SHARES_NUMBER = 'output_product_minimum_shares_number',
    OUTPUT_PRODUCT_UNIT_PRICE = 'output_product_unit_price',

    INPUT_PRODUCT_SHARES_NUMBER = 'input_product_shares_number',
    INPUT_PRODUCT_TEMPORARY_DISMEMBERMENT_RATE_PERCENTAGE = 'input_product_temporary_dismemberment_rate_percentage',

    INPUT_FILE = 'input_file',
    INPUT_FILE_WITH_OPTIONS = 'input_receipt_file',
    INPUT_SORT = 'ordonnancement',

    CALCULATE = 'calculate',
    PRESENTATION = 'presentation',

    BUTTON = "button",
    BUTTON_DOCUSIGN_IFRAME = 'button_docusign_iframe',
    BUTTON_PRINT = 'button_print',
    BUTTON_CREATE_PROSPECT = 'button_create_prospect',

    OUTPUT_SCORING_RESULT = 'output_scoring_number',
    OUTPUT_SCORING_TEXT = 'output_scoring_text'
}
