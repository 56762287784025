import BlockGatewayInterface from '../BlockGatewayInterface';
import Block from '../Block';

export default class FindAllBlocksUseCase
{
  public blockGatewayInterface;

  constructor(BlockGatewayInterface: BlockGatewayInterface) {
    this.blockGatewayInterface = BlockGatewayInterface;
  }

  execute(pageId: string): Promise<[]|Block[]> {
    return this.blockGatewayInterface.getBlocksByPageId(pageId)
  }
}
