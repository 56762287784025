import React, {FunctionComponent} from 'react';

const Next: FunctionComponent = () => {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.70874 14H16.8787L11.9987 18.88C11.6087 19.27 11.6087 19.91 11.9987 20.3C12.3887 20.69 13.0187 20.69 13.4087 20.3L19.9987 13.71C20.3887 13.32 20.3887 12.69 19.9987 12.3L13.4187 5.70002C13.2319 5.51276 12.9783 5.40753 12.7137 5.40753C12.4492 5.40753 12.1956 5.51276 12.0087 5.70002C11.6187 6.09002 11.6187 6.72002 12.0087 7.11002L16.8787 12H5.70874C5.15874 12 4.70874 12.45 4.70874 13C4.70874 13.55 5.15874 14 5.70874 14Z"
        fill="white" />
      <defs>
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </defs>
    </svg>
  );
}

export default Next;
