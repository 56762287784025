import {FunctionComponent} from 'react'
import { RouteComponentProps } from 'react-router'
import HeaderManageComponent from '../component/header/HeaderManageComponent'
import BlockValidationSummaryComponent from '../component/block/BlockValidationSummaryComponent'

type Params = {
  dossierId: string
};

const DashboardDossier: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {
  const dossierId = match?.params?.dossierId

  return (
    <>
      <HeaderManageComponent dossierId={dossierId} />
      <BlockValidationSummaryComponent dossierId={dossierId} />
    </>
  )
}

export default DashboardDossier;
