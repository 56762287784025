import {LogicOperatorDefinition} from './LogicOperatorDefinition';

export default class LogicalOperator
{
  static validate(conditions: boolean[], logicalOperator: LogicOperatorDefinition): boolean {
    switch (logicalOperator.toUpperCase()) {
      case LogicOperatorDefinition.AND:
        return !(conditions.indexOf(false) !== -1)
      case LogicOperatorDefinition.OR:
        return conditions.indexOf(true) !== -1
      default:
        return !(conditions.indexOf(false) !== -1)
    }
  }
}
