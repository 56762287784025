import SignedDocumentDetailGatewayInterface from '../../domain/Docusign/ReceiptDetailGatewayInterface';
import { SignedDocument } from '../../domain/Docusign/SignedDocument';

export default class GetListSignedDocumentUseCase
{
  public signedDocumentDetailGateway;

  constructor(signedDocumentDetailGateway: SignedDocumentDetailGatewayInterface) {
    this.signedDocumentDetailGateway = signedDocumentDetailGateway;
  }

  execute(folderId: string): Promise<SignedDocument[]|null> {
    return this.signedDocumentDetailGateway.retrieveList(folderId)
      .then((response) => {
        return response
      })
      .catch(() => {
        return null
      });
  }
}
