export default class ElementPreview {
  private readonly _presentation: string;
  private readonly _name: string;

  constructor(presentation: string, name: string) {
    this._presentation = presentation;
    this._name = name;
  }

  get presentation(): string {
    return this._presentation
  }

  get name(): string {
    return this._name
  }
}
