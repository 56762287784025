import React from 'react'

import Modal from "../../modal/Modal"
import { PropsModal } from './types'

const FolderModal: React.FC<PropsModal> = ({ title, description, customClassName, hide, children, forceClose }) => {
  return (
    <Modal title={title}
           description={description}
           customClassName={customClassName}
           hide={hide}
           forceClose={forceClose}
    >
      {children}
    </Modal>
  )
}

export default FolderModal
