/* eslint-disable  @typescript-eslint/no-explicit-any */

import {ButtonHTMLAttributes, FunctionComponent, ReactNode} from 'react';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  classes: string,
  label?: string,
  type?: 'submit' | 'reset' | 'button';
  onClick?: any,
  disabled?: boolean,
  position?: string
  icon?: ReactNode
}

const Button: FunctionComponent<IButtonProps> = ({classes, label, type, onClick, position, icon, disabled, ...rest}) => {

  return (
      <button type={type ? type : 'button'} className={`${classes}`} onClick={onClick} {...rest} disabled={disabled}>
        {position === 'left' && <span className="left">{icon}</span>}
        {label}
        {position === 'right' && <span className="right">{icon}</span>}
        {position === 'alone' && icon }
      </button>
  );

};

export default Button;
