import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Receipt from '../validation/Receipt'
import {ReceiptView} from '../../../domain/Receipt/ReceiptView'
import FetchDocumentGateway from '../../../gateway/Document/FetchDocumentGateway'
import storage from '../../util/storage'
import IconFactory from '../svg/IconFactory'
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway'
import GetDetailAndStoreItUseCase from '../../../useCase/Folder/GetDetailAndStoreItUseCase'
import LocalStorageGateway from '../../../gateway/Form/LocalStorageGateway'
import {ErrorResponse} from '../../../domain/Api/Type'
import ErrorModal from '../modal/ErrorModal';
import { SignedDocument } from '../../../domain/Docusign/SignedDocument';
import SignDocument from '../validation/SignDocument';
import Spinner from '../svg/Spinner';

interface Props {
  dossierId: string;
  receipts: { [key: string]: ReceiptView[] };
  documents : SignedDocument[]|null;
}

const ReceiptsValidation: React.FC<Props> = ({dossierId, receipts, documents}) => {
  const {t} = useTranslation()
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string|null>(null)

  const downloadAllDocuments = () => {
    if(isDownloading)
      return
    setDownloading(true)
    const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())

    getDetailAndStoreItUseCase?.downloadSignedDocument(dossierId)?.then((response) => {
      if (response !== null) {
        const url = URL.createObjectURL(response)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `dossier-${dossierId}.zip`
        a.click()
        window.URL.revokeObjectURL(url)
      }
    })
      .catch((errors: ErrorResponse) => {
        if (errors.errors?.length > 0) {
          setErrorMessage(errors.errors[0].message);
        } else {
          setErrorMessage(t(`docusign.unexpected_error`));
        }
      })
      .finally(() =>
        setDownloading(false)
      )
  }

  const getDownloadCaption = isDownloading ? t('button.download-in-progress') : t('button.download-document')
  const downloadDocWidget = (null !== documents && documents?.length > 0) ? (
    <div className="button-preview button-preview--reverse button-preview-download"
         onClick={() => downloadAllDocuments()}
    >
      <IconFactory type="download"/>
      <span className={`u-prs u-pxs`}>{getDownloadCaption}</span>
    </div>
  ) : ( (null !== documents ) ? <Spinner /> : (<span className="u-txt-italic">{t('docusign.documents_not_available')}</span>))

  return (
    <>
      { errorMessage && <ErrorModal message={errorMessage} onClose={() => setErrorMessage(null)} /> }
      <div
        className={`box-elevations box-elevations--base box-elevations--mb u-mxAuto`}>
        <div className="flex-container">

          <h2 className="help-wrapper u-mbs">
            {t('validation.receipts-signed')}
          </h2>
          {documents?.map((document, index) => (
              <div key={document.id+'-'+index} className="col-md-12">
                <SignDocument folderId={dossierId} document={document}/>
              </div>
            ))}
        </div>
        {downloadDocWidget}

        <div className="flex-container">
          <h2 className="help-wrapper u-mbm u-mtl">
            {t('validation.receipts')}
          </h2>

          {Object.keys(receipts).map((type, index) => (
            <div key={type} className="col-md-12">
              <h4 className={`${index > 0 ? 'u-mtm' : ''}`}>{type}</h4>
              {receipts[type].map((receipt) => (
                <Receipt key={receipt.id} receipt={receipt}/>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReceiptsValidation
