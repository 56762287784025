import React, {FunctionComponent} from 'react';

const GifFile: FunctionComponent = () => {
    return (
      <svg
        height="64px"
        width="64px"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        fill="#000000"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
          <path
            style={{
              fill: "#E2E5E7",
            }}
            d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
          />
          <path
            style={{
              fill: "#B0B7BD",
            }}
            d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
          />
          <polygon
            style={{
              fill: "#CAD1D8",
            }}
            points="480,224 384,128 480,128 "
          />
          <path
            style={{
              fill: "#A066AA",
            }}
            d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
          />
          <g>
            <path
              style={{
                fill: "#FFFFFF",
              }}
              d="M199.84,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808 c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288 c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448 c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248 C203.024,369.552,202.016,371.712,199.84,374.16z"
            />
            <path
              style={{
                fill: "#FFFFFF",
              }}
              d="M224.944,303.152c0-10.496,16.896-10.88,16.896,0v73.024c0,10.624-16.896,10.88-16.896,0V303.152z"
            />
            <path
              style={{
                fill: "#FFFFFF",
              }}
              d="M281.12,312.096v20.336h32.608c4.608,0,9.216,4.608,9.216,9.088c0,4.224-4.608,7.664-9.216,7.664 H281.12v26.864c0,4.48-3.2,7.936-7.68,7.936c-5.632,0-9.072-3.456-9.072-7.936v-72.656c0-4.608,3.456-7.936,9.072-7.936h44.912 c5.632,0,8.96,3.328,8.96,7.936c0,4.096-3.328,8.688-8.96,8.688H281.12V312.096z"
            />
          </g>
          <path
            style={{
              fill: "#CAD1D8",
            }}
            d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
          />
        </g>
      </svg>
    );
}

export default GifFile;
