import DocumentGatewayInterface from '../DocumentGatewayInterface';
import Receipt from '../../Receipt/Receipt';
import Document from '../Document';
import StoreGatewayInterface from '../../Store/StoreGatewayInterface';

export default class SendDocumentUseCase
{
  public documentGateway
  public storeGateway

  constructor(DocumentGateway: DocumentGatewayInterface, StoreGateway: StoreGatewayInterface) {
    this.documentGateway = DocumentGateway
    this.storeGateway = StoreGateway
  }

  execute(blockId: string, documents: Document[]): Promise<Receipt[]|null> {
    return this.documentGateway.send(blockId, documents).then(receipts => {
      this.storeGateway.saveReceipts(receipts)
      return receipts
    })
  }
}
