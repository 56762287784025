import ClientGatewayInterface from '../../domain/Client/ClientGatewayInterface';
import {Folder} from '../../domain/Folder/Folder';

export default class Authorize
{
  public clientGateway;

  constructor(ClientGateway: ClientGatewayInterface) {
    this.clientGateway = ClientGateway;
  }

  async execute(dossierId: string, clientId: number): Promise<Folder|Error[]> {
    return await this.clientGateway.authorize(dossierId, clientId).then(response => response)
  }
}
