import React, { FunctionComponent, useState, useEffect, useMemo } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import Page from '../../domain/Page/Page'

import WizardComponent from '../component/wizard/WizardComponent'
import HeaderComponent from '../component/header/HeaderComponent'
import FooterComponent from '../component/footer/FooterComponent'
import BlocksComponent from '../component/block/BlocksComponent'

// Redux
import { useAppDispatch } from '../../store/hook'
import {
  setAllPages,
  updateNextPageId,
  updateNextPageIdAndCurrentPageIddByGoBack,
  updateProgressValueByIdGoBack,
} from '../../store/wizzard/wizzardStep'

import FindAllPagesUseCase from '../../domain/Page/UseCase/FindAllPagesUseCase'
import FetchPageGateway from '../../gateway/Page/FetchPageGateway'
import storage from '../util/storage'
import Back from '../component/svg/Back'
import { useTranslation } from 'react-i18next'

type Params = {
  pageId: string
  blockId: string
}

const Form: FunctionComponent<RouteComponentProps<Params>> = ({ match }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [pages, setPages] = useState<Page[] | null>(null)
  const [showBackButton, setShowBackButton] = useState<boolean>(false)

  useEffect(() => {
    if (!storage.getFolderId()) {
      history.push('/dossier')
    }
  }, [history])

  useMemo(() => {
    const findAllPagesUseCase = new FindAllPagesUseCase(new FetchPageGateway())
    findAllPagesUseCase.execute().then((pages) => {
      dispatch(setAllPages(JSON.stringify(pages)))
      const initialPageId = match.params.pageId ?? pages[0].id
      dispatch(updateNextPageId({ id: initialPageId }))
      setPages(pages)

      const dossierState = storage.getDossierState()
      setShowBackButton(
        pages[0].id !== match.params.pageId &&
        dossierState !== 'signed' &&
        dossierState !== 'signature'
      )

      if (!match.params.pageId) {
        history.push(`/form/${pages[0].id}`)
      }
    })
  }, [dispatch, history, match.params.pageId])

  const handleBackButtonClick = () => {
    dispatch(updateProgressValueByIdGoBack({ id: null }))
    dispatch(updateNextPageIdAndCurrentPageIddByGoBack({ id: match.params.pageId }))
    history.goBack()
  }

  return (
    <>
      {pages && match.params.pageId && (
        <div>
          <HeaderComponent />
          <div className="wrap u-mxAuto">
            {showBackButton && (
              <a href="#" onClick={handleBackButtonClick} className="back">
                <Back color="var(--ink-1)" />
                <span className="u-txt-size-s u-txt-color-ink-1 u-mxs">{t('button.back')}</span>
              </a>
            )}
          </div>
          <WizardComponent pages={pages} currentPageId={match.params.pageId} />
          <BlocksComponent pageId={match.params.pageId} blockId={match.params.blockId || null} />
          <FooterComponent />
        </div>
      )}
    </>
  )
}

export default Form
