import React, {FunctionComponent} from 'react';

const CalendarInput: FunctionComponent = () => {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 9.16669C12 8.89054 11.7761 8.66669 11.5 8.66669H9.16667C8.89052 8.66669 8.66667 8.89054 8.66667 9.16669V11.5C8.66667 11.7762 8.89052 12 9.16667 12H11.5C11.7761 12 12 11.7762 12 11.5V9.16669ZM11.1667 0.666687C10.8905 0.666687 10.6667 0.890545 10.6667 1.16669V2.00002H5.33333V1.16669C5.33333 0.890545 5.10948 0.666687 4.83333 0.666687H4.5C4.22386 0.666687 4 0.890545 4 1.16669V2.00002H3.33333C2.59333 2.00002 2.00667 2.60002 2.00667 3.33335L2 12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33335C14 2.60002 13.4 2.00002 12.6667 2.00002H12V1.16669C12 0.890545 11.7761 0.666687 11.5 0.666687H11.1667ZM13 12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12V4.66669H13V12Z" fill="var(--ink-1)"/>
    </svg>
  );
}

export default CalendarInput;
