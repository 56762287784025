import GetUrl from "../../../../useCase/Docusign/GetUrl";
import FetchDocusignGateway from "../../../../gateway/Docusign/FetchDocusignGateway";
import React from "react";
import Loader from "../../loader/Loader";
import Button from "../../button/Button";
import {EFolderStatus} from "../../../../domain/Folder/EFolderStatus";
import {EDocusignButton} from "../../../../domain/Docusign/EDocusignButton";

type Props = {
    id: string
    docusignId?: string
    label?: string
    dossierState: string
}

class DocusignIframeButton extends React.Component<Props> {

    private Iframe: HTMLIFrameElement | undefined;

    public state = {
        isLoading: false,
        url: "",
        iframeRef: null
    }

    componentDidMount() {
        global.window.addEventListener('message', this.handleIframeTask, false);
    }

    handleIframeTask = (e) => {
        const storage = localStorage.getItem("id-btn-docusign")
        if (typeof e.data === "string" && storage !== "") {
            if (storage === 'error') {
                setTimeout(
                    () => this.setState({url: ""}),
                    5000
                )
            }
            global.window.removeEventListener('message', this.handleIframeTask, false);
        }
    };

    handleOnClick = (): void => {
        if (undefined !== this.props.docusignId) {
            localStorage.setItem("id-btn-docusign", this.props.id)
            this.setState({isLoading: true})
            const urlObject = new GetUrl(
                new FetchDocusignGateway()
            )
            urlObject.execute(this.props.docusignId).then(
                response => {
                    this.setState({isLoading: false})
                    this.setState({url: response})
                }
            )
        }
    }

    presentSignButton = (): JSX.Element => {
        return <>
            {(!this.state.isLoading && (this.state.url === '' || undefined === this.state.url) &&
                <div className="col-md">
                    <Button
                        label={this.props.label}
                        classes={'button button-primary--outline button--fixed button--large u-mxAuto'}
                        type={'button'}
                        disabled={false}
                        onClick={this.handleOnClick}
                    />
                </div>
            )}
        </>
    }

    onIframeRef = (node: HTMLIFrameElement): void => {
        node !== null
        && node !== undefined
        && node.contentWindow !== null
        && node.contentWindow.addEventListener("load", () => {
            const iFrameHeadStyleElement = document.createElement('style')
            iFrameHeadStyleElement.innerHTML = `
                .DiaOSignDialog {
                    border: none !important;
                    box-shadow: none !important;
                    -moz-border-radius: unset !important;
                    -moz-box-shadow: none !important;
                    -webkit-border-radius: unset !important;
                    -webkit-box-shadow: none !important;                                
                }
            `.trim()
            node.contentWindow !== null
            && node.contentWindow.document !== null
            && node.contentWindow.document.head !== null
            && node.contentWindow.document.head.appendChild(iFrameHeadStyleElement)
        });

        this.Iframe = node;
    };

    presentDocusignIframe = (): JSX.Element => {
        return <>
            {('' !== this.state.url && undefined !== this.state.url &&
                <div className="col-md-12 wrapper-iframe">
                    <iframe
                        title={''}
                        id={this.props.id}
                        src={this.state.url}
                        className={'iframe'}
                        ref={this.onIframeRef}
                    />
                </div>
            )}
        </>
    }

    alreadySigned = (): boolean => {
        if (this.props.dossierState === EFolderStatus.Signed || this.props.dossierState === EFolderStatus.Mission_validated) {
            return true
        }
        switch (this.props.id) {
            case EDocusignButton.MISSION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return this.props.dossierState.includes(EFolderStatus.Mission_signed_by_subscriber)
            case EDocusignButton.MISSION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return this.props.dossierState.includes(EFolderStatus.Mission_signed_by_co_subscriber)
            case EDocusignButton.MISSION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                return this.props.dossierState.includes(EFolderStatus.Mission_signed_by_subscriber_spouse)
            case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return this.props.dossierState.includes(EFolderStatus.Subscription_signed_by_subscriber)
            case EDocusignButton.SUBSCRIPTION_CO_SUBSCRIBER_SIGN_BUTTON_ELEMENT_ID:
                return this.props.dossierState.includes(EFolderStatus.Subscription_signed_by_co_subscriber)
            case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_SPOUSE_SIGN_BUTTON_ELEMENT_ID:
                return this.props.dossierState.includes(EFolderStatus.Subscription_signed_by_subscriber_spouse)
            case EDocusignButton.SUBSCRIPTION_SUBSCRIBER_ADVISOR_SIGN_BUTTON_ELEMENT_ID:
                return this.props.dossierState.includes(EFolderStatus.Subscription_signed_by_subscriber_advisor)
            default:
                return true
        }
    }

    componentWillUnmount(): void {
        localStorage.setItem("id-btn-docusign", "")
        global.window.removeEventListener('message', this.handleIframeTask, false);
    }

    render = (): JSX.Element | null => {
        if (!this.alreadySigned()) {
            return (
                <>
                    {this.presentSignButton()}
                    {this.presentDocusignIframe()}
                    {this.state.isLoading && <div className="col-md"><Loader/></div>}
                </>
            )
        } else {
            return null
        }

    }
}

export default DocusignIframeButton