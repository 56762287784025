
import {FC} from 'react'
import Select from 'react-select'
import {Controller} from 'react-hook-form'

import {ISelectProps} from './ISelectProps'
import FieldErrorMessage from '../messages/FieldErrorMessage'
import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {updateElementTimestamp} from '../../../../store/element/elements'
import FormGateway from '../../../../gateway/Form/FormGateway'
import SaveUseCase from '../../../../domain/Form/SaveUseCase'
import getElementsIdToRender from '../../../util/getElementsIdToRender'
import Element from '../../../../domain/Element/Element'
import {useTranslation} from 'react-i18next';

const SelectCustom: FC<ISelectProps> = ({
                                    options,
                                    id,
                                    name,
                                    register,
                                    error,
                                    control,
                                    label,
                                    classes,
                                    required,
                                    multiple,
                                    defaultValue,
                                    schema,
                                    readonly,
                                    ...rest
                                  }) => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch()

  const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
  const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
  const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
  const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
  const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
  const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

  function handleChange(valueSelect) {
    const data = [];
    data[id] = (multiple) ? valueSelect.map(object => object.value) : valueSelect

    const saveUseCase = new SaveUseCase(new FormGateway())
    saveUseCase.execute(data).then(() => {
      const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
      elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
    })
  }

  const defaultValueFunction = (value) => {
    if (multiple) {
      return options?.filter(option => defaultValue?.includes(option.value))
    }

    return options?.filter(option => (option.value === value || (option.value === defaultValue && value === undefined)))
  };
  return (
    <div className={`${classes}  ${readonly && "readonly-element"}`}>
      <div className="form-select u-txt-color-text-light" title={rest.help}>

        <label htmlFor="select" className="u-txt-size-l u-txt-color-text-light u-mbb">{label}</label>

        <div>
          <Controller
            name={id}
            options={options}
            control={control}
            rules={schema}
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
              <Select
                menuPosition="absolute"
                menuPortalTarget={document.body}
                placeholder={t('common.select-placeholder')}
                options={options}
                isDisabled={readonly}
                defaultValue={defaultValueFunction(value)}
                isMulti={multiple}
                isClearable={!required}
                register={register}
                onChange={val => {
                  if (multiple) {
                    onChange(val.map(object => object.value));
                    handleChange(val);

                    return false
                  }

                  onChange(val ? val.value : "");
                  handleChange(val ? val.value : "");
                }}
              />
            )}
          />
        </div>

        {error?.message && <FieldErrorMessage message={error.message} />}
      </div>
    </div>
  )
};

export default SelectCustom;
