import SignedDocumentDetailGatewayInterface from '../../domain/Docusign/ReceiptDetailGatewayInterface';

export default class PreviewSignedDocumentUseCase
{
  public signedDocumentDetailGateway;

  constructor(signedDocumentDetailGateway: SignedDocumentDetailGatewayInterface) {
    this.signedDocumentDetailGateway = signedDocumentDetailGateway;
  }

  execute(folderId: string, documentId: string): Promise<{ id: string, resource: string, title: string, mimetype: string|null}|null> {
    return this.signedDocumentDetailGateway.getByDocumentId(folderId, documentId).then(documentDetail => {
      if (documentDetail) {
        return {
          id: documentDetail.id,
          resource: documentDetail.resource,
          title: documentDetail.title,
          mimetype: documentDetail.mimetype
        }
      } else {
        return null
      }
    })
  }
}
