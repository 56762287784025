import ElementInterface, {IAttributes} from './ElementInterface'
import ICondition from "../Condition/ICondition";


export default class Element implements ElementInterface {

    id: string;
    name: string;
    type: string;
    cssClasses: string;
    attributes: IAttributes;
    condition?: ICondition;
    calculate?: string;
    reference?: string;
    timestamp?: number;

    constructor(
        id: string,
        name: string,
        type: string,
        cssClasses: string,
        attributes: IAttributes,
        condition?: ICondition,
        calculate?: string,
        reference?: string
    ) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.cssClasses = cssClasses;
        this.attributes = attributes;
        this.condition = condition
        this.calculate = calculate
        this.reference = reference
        this.timestamp = new Date().getTime()
    }
}
