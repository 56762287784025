import FooterGatewayInterface from '../../domain/Footer/FooterGatewayInterface'
import {FooterInterface} from '../../domain/Footer/Footer'

export default class Retrieve
{
  public gateway: FooterGatewayInterface;

  constructor(ContactGateway: FooterGatewayInterface) {
    this.gateway = ContactGateway;
  }

  async execute(): Promise<FooterInterface|null> {
    const contact = await this.gateway.retrieve();
    return contact;
  }
}
