import React, {FunctionComponent} from 'react';

interface IProps {
  classes?: string,
  label?: string,
  help?: string,
}

const MyUploaderHeader: FunctionComponent<IProps> = ({classes, label, help}) => {

  return (
    <div className={classes}>
      {(label && <h5 className="help-wrapper u-mbs">{label}</h5>)}
      {(help && <p className="u-txt-size-s u-txt-color-text-light  u-mbm">{help}</p>)}
    </div>
  )
}

export default MyUploaderHeader;



