import {createSlice} from '@reduxjs/toolkit'

// Define a type for the slice state
interface HeaderTimestamp {
  timestamp: number,
}

// Define the initial state using that type
const initialState: HeaderTimestamp = {
  timestamp: Date.now(),
}

export const headerSlice = createSlice({
  name        : 'headerTimestamp',
  initialState: initialState,
  reducers    : {
    setTimestamp: (state, action) => {
      state.timestamp = action.payload.timestamp
    }
  }
})

export const {
  setTimestamp,
} = headerSlice.actions

export default headerSlice.reducer
