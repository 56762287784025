import React from 'react';
import { useTranslation } from 'react-i18next';

import { PropsDetails } from './types';

const FolderDetails: React.FC<PropsDetails> = ({ FolderDetail, error, FolderStatuses }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="u-txt-size-l u-txt-bold u-mb0">{FolderDetail.present}</div>
      <div className="u-txt-size-s u-txt-color-ink-1">
        <span>{t('common.folder')} n° {FolderDetail.id}</span>
        {FolderDetail.advisor?.external_id && (<>
          <span>&nbsp;</span>
          <span>({FolderDetail.advisor?.external_id})</span>
        </>)}
      </div>
      {FolderDetail.subscriptionGlobalAmount && <div className="u-txt-size-xs u-mb0 u-max-w-90">{t('common.subscriptionGlobalAmount')} : {FolderDetail.subscriptionGlobalAmount}</div>}
      {FolderStatuses.length>0 &&<div className="u-txt-size-xs u-mb0 u-max-w-90">({FolderStatuses.join(' - ')})</div>}
      {error &&
        <span className="flex u-mts u-txt-size-s notification notification--error notification--error-small">
                    {t('folder.error.cannot-delete-subscription-folder')}
                </span>
      }
    </div>
  );
}

export default FolderDetails;
