import {FC, InputHTMLAttributes} from 'react'
import {IFormFieldProps} from '../../IFormFieldProps';
import parse from "html-react-parser";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
    id?: string
    classes: string,
    defaultValue?: string
}


const ScoringTextInput: FC<IProps> = (
    {
        id,
        classes,
        label,
        defaultValue,
        ...rest
    }
) => {

    const readOnlyClassName = rest.readonly ? 'readonly-element' : ''

    return (

        <div className={classes}>
            <div className={`form-floating`}>
                <input ref={(node) => {
                       if (node) {
                          node.style.setProperty('color', 'black', 'important') 
                       }
                       }}
                       className={`form-control ${readOnlyClassName}`}
                       id={id}
                       value={defaultValue}
                       defaultValue={''}
                       {...rest}
                       name={id}
                       required={false}
                       readOnly={rest.readonly}
                       title={rest.help}
                />

                <label className="u-txt-size-l u-txt-color-text-light u-mbb" htmlFor={id}>{parse(label ?? '')}</label>
            </div>
        </div>

    )
};

export default ScoringTextInput;
