import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"

import {downloadFile} from '../../util/DownloadFile'
import IconFactory from "../svg/IconFactory"
import MimeTypeFactory from "../svg/mimetype/MimeTypeFactory"
import PreviewDocumentModal, { IPreviewFile } from "../modal/PreviewModal"
import Spinner from "../svg/Spinner"
import ErrorModal from "../modal/ErrorModal"
import { SignedDocument } from "../../../domain/Docusign/SignedDocument"
import PreviewSignedDocumentUseCase from "../../../useCase/Docusign/PreviewSignedDocumentUseCase"
import FetchSignedDocumentGateway from "../../../gateway/Docusign/FetchSignedDocumentGateway"
import { PreviewFile } from "../modal/PreviewFile"

type Props = {
  folderId: string
  document: SignedDocument
}

const SignDocument: FunctionComponent<Props> = ({folderId, document}) => {
  const {t} = useTranslation()

  const [previewDocument, setPreviewDocument] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string|null>(null)

  const getDocumentFile = (documentId: string): Promise<{ id: string, resource: string, title: string, mimetype: string|null}|null> => {
    return new PreviewSignedDocumentUseCase(new FetchSignedDocumentGateway()).execute(folderId, documentId).then(response => {
        return response
    })
  }
  const downloadDocument = (document: SignedDocument) => () => {
    setLoading(true)
    getDocumentFile(document.id).then(response => {
      if (response) {
        downloadFile(response, response.resource)
        setLoading(false)
      } else {
        setLoading(false)
        setError(t('ged.download_failed'))
      }
    })
  }

  const fetchDocumentFile = (documentId: string): Promise<IPreviewFile  |null> => {
    return getDocumentFile(documentId).then(response => {
      if (response) {
        return new PreviewFile(
            response.resource,
            response.title,
            response.mimetype
        )
      } else {
        setError(t('ged.download_failed'))
        return null
      }
    })
  }
  const buttonClassName = isLoading||previewDocument ? "button-disabled" : "button-enabled"
  return (
    <>
      { error && <ErrorModal message={error} onClose={() => setError(null)} /> }
      {previewDocument && <PreviewDocumentModal id={document.id} download={fetchDocumentFile} hide={() => setPreviewDocument(false)}/>}
      <div className={`validation--document`}>
        <div className="validation__label">
          <MimeTypeFactory filename={document.name} />
          <span>{document.name}</span>
        </div>
        <div className={`validation__cta`} >
          <div className="pj-container-min-preview pj-figure" title={previewDocument ? t('button.view-ongoing') : t('button.view')}>
            <div className={`button align-items-end button--small pj-file-min-preview ${buttonClassName}`}
              onClick={() => setPreviewDocument(true)}>
              {previewDocument ? <Spinner /> : <IconFactory type={"eye"} className={'pj-preview'}/>}
            </div>
          </div>
        </div>
        <div className={`validation__cta`} >
          <div className="pj-container-min-preview pj-figure" title={isLoading ? t('button.download-ongoing') : t('button.download')}>
            <div className={`button align-items-end button--small pj-file-min-preview ${buttonClassName}`}
              onClick={downloadDocument(document)}>
              {isLoading ? <Spinner /> : <IconFactory type={"download"} className={'pj-preview-fill'}/>}
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default SignDocument
