import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import '../../../assets/styles/components/_modal.scss';

type Props = {
  onConfirm: (data: any) => void;
  onClose: () => void;
  message?: string;
  optionStyles?: Modal.Styles;
};

const ConfirmationModalWithReason: FunctionComponent<Props> = ({ onConfirm, onClose, message, optionStyles }) => {
  Modal.setAppElement('#root');

  const { t } = useTranslation();
  const [userMessage, setUserMessage] = useState(message || '');
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)

  useEffect(() => {
    setIsConfirmDisabled(userMessage.trim() === '');
  }, [userMessage])


  const onClick = () => {
    onConfirm(userMessage);
    onClose();
  };

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      minWidth: "40%",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
    },
  };

  const modalStyles = useMemo(() => {
    if (optionStyles) {
      return {
        ...styles,
        ...optionStyles,
      };
    }
    return styles;
  }, [optionStyles]);

  return (
    <>
      <Modal isOpen={true} style={modalStyles}>
        <div className="content-small">
          <p className="content-small__text u-pbb">
            {message ? message : t('modal.confirmation.message-state-update')}
          </p>
          <div className="flex-container">
            <div className="col-12">
              <div className="form-control__input u-mbm">
            <textarea
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder={t('modal.confirmation.enter-reason')}
            />
              </div>
            </div>
          </div>
          <div className="content__buttons">
            <button
                type="button"
                className="button button-error button--large"
                onClick={onClose}
              >
                {t('modal.confirmation.no')}
              </button>
              <button
                type="button"
                className="button button-primary button--large"
                onClick={onClick}
                disabled={isConfirmDisabled}
              >
              {t('modal.confirmation.yes')}
            </button>
            </div>
          </div>
      </Modal>
    </>
);
};

export default ConfirmationModalWithReason;
