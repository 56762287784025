import React from 'react';
import { useTranslation } from 'react-i18next';

import {ViewPageInterface} from '../../../domain/ViewDossier/ViewPage'
import PresentationPage from './partials/PresentationPage'
import {Tab, Tabs, TabsLikeWizard} from '../tab/Tab'

interface Props {
  dossierId: string,
  viewPages: ViewPageInterface[],
}

const PresentationDossier: React.FC<Props> = ({dossierId, viewPages}) => {
  const {t} = useTranslation()

  return (
    <div className="box-elevations box-elevations--base u-mxAuto u-pxm">
      <TabsLikeWizard classes={`wizzard`}>
      {viewPages.map((page: ViewPageInterface) => {
        return <Tab key={page.id} label={page.name}>
          <PresentationPage page={page} />
        </Tab>
      })}
      </TabsLikeWizard>
    </div>
  );
};

export default PresentationDossier
