import OptionsInterface, {OptionsAttributesInterface} from './OptionsInterface';

export default class DatalistOptions implements OptionsInterface{
  options: [] & OptionsAttributesInterface

  constructor(options) {
    this.options = options
  }

}
