interface SignedDocumentDetailInterface {
    id: string
    resource: string
    title: string
    mimetype: string|null
}


export default class SignedDocumentDetail implements SignedDocumentDetailInterface {
    id: string
    resource: string
    title: string
    mimetype: string|null

    constructor(id: string, resource: string, title: string, mimetype: string|null) {
        this.id = id
        this.resource = resource
        this.title = title
        this.mimetype = mimetype
    }
}

export type {SignedDocumentDetailInterface}

export {SignedDocumentDetail}