import {FC, useEffect, useRef} from 'react';
import {ENotificationType} from "./ENotificationType";

interface INotificationProps {
    label: string,
    type: ENotificationType
}

const Notification: FC<INotificationProps> = ({label, type}) => {
    const notificationRef = useRef<HTMLDivElement>(null)

    return (
        <div className={`notification notification--${type} `} ref={notificationRef}>
            {label}
            <span className="notification__close" onClick={() => {notificationRef.current?.remove()}}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill={`var(--notify-${type}-text)`} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.6667 4.27325L11.7267 3.33325L8.00001 7.05992L4.27334 3.33325L3.33334 4.27325L7.06001 7.99992L3.33334 11.7266L4.27334 12.6666L8.00001 8.93992L11.7267 12.6666L12.6667 11.7266L8.94001 7.99992L12.6667 4.27325Z"
                    fill="#191516"/>
              </svg>
            </span>
        </div>
    );

};

export default Notification;
