import React from 'react';
import { useTranslation } from 'react-i18next';

import {CustomerInfoRecallInterface} from '../../../../domain/CustomerInfoRecall/CustomerInfoRecall'

interface Props {
  customerInfoRecall: CustomerInfoRecallInterface,
}

const PhysicalPerson: React.FC<Props> = ({customerInfoRecall}) => {
  const {t} = useTranslation()

  const translationKey = customerInfoRecall.birthCity ? 'validation.customer-info-recall-birth-date-with-city' : 'validation.customer-info-recall-birth-date-without-city';

  return (
    <div className="">
        <div className="">
          {customerInfoRecall.civility}
          &nbsp;{customerInfoRecall.firstName}
          &nbsp;{customerInfoRecall.lastName}
        </div>
        <div className="">
          {(customerInfoRecall.managerBirthDate || customerInfoRecall.managerBirthCity) && t(translationKey, {
            date: customerInfoRecall.birthDay,
            city: customerInfoRecall.birthCity,
            country: customerInfoRecall.birthCountry,
          })}
        </div>
        <div className="">
          {customerInfoRecall.email}
        </div>
      </div>
)
  ;
};

export default PhysicalPerson;
