import ClientInterface from "./ClientInterface";

export default class Client implements ClientInterface {
  id: number
  civility: string
  first_name: string
  last_name: string
  username: string
  email: string

  constructor(
    id: number,
    civility: string,
    first_name: string,
    last_name: string,
    username: string,
    email: string,
  ) {
    this.id = id
    this.civility = civility
    this.first_name = first_name
    this.last_name = last_name
    this.username = username
    this.email = email
  }
}
