import ViewDossierGatewayInterface from '../../domain/ViewDossier/ViewDossierGatewayInterface'
import {ViewPageInterface} from '../../domain/ViewDossier/ViewPage'

export type RetrieveViewDossierCommand = {
  dossierId: string
}

export default class RetrieveViewDossierUseCase
{
  constructor(private readonly customerInfoRecallGateway: ViewDossierGatewayInterface) {}

  async handle(retrieveViewDossierCommand: RetrieveViewDossierCommand): Promise<ViewPageInterface[] | null> {
    const response = await this.customerInfoRecallGateway.retrieve(retrieveViewDossierCommand.dossierId)
    if (response) {
      return response
    }
    return null
  }
}
