import React, {FC, useState} from 'react'
import Select from 'react-select'
import {Controller} from 'react-hook-form'

import {ISelectProps} from '../select/ISelectProps'
import FieldErrorMessage from '../messages/FieldErrorMessage'
import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import {updateElementTimestamp} from '../../../../store/element/elements'
import FormGateway from '../../../../gateway/Form/FormGateway'
import SaveUseCase from '../../../../domain/Form/SaveUseCase'
import getElementsIdToRender from '../../../util/getElementsIdToRender'
import Element from '../../../../domain/Element/Element'
import {useTranslation} from 'react-i18next';
import {IOptionType} from "../select/IOptionType";
import parse from "html-react-parser";


interface IOrderedSelectProps extends ISelectProps {
    setAvailableChildrenOptions: React.Dispatch<React.SetStateAction<IOptionType[]>>
    optionsList: IOptionType[]
}

const OrderedSelectItem: FC<IOrderedSelectProps> = (
    {
        options,
        id,
        error,
        control,
        label,
        classes,
        required,
        multiple,
        defaultValue,
        schema,
        readonly,
        optionsList,
        setAvailableChildrenOptions,
        ...rest
    }
) => {
    const {t} = useTranslation()
    const dispatch = useAppDispatch()

    const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
    const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
    const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
    const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
    const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
    const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

    const [valueInput, setValueInput] = useState<string | undefined | string[]>(defaultValue)

    function handleChange(valueSelect) {
        const data = [];
        data[id] = (multiple) ? valueSelect.map(object => object.value) : valueSelect
        const saveUseCase = new SaveUseCase(new FormGateway())
        saveUseCase.execute(data).then(() => {
            const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
            elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
        })
        setValueInput(valueSelect)
        updateAvailableChildrenOptions(valueSelect)
    }

    function updateAvailableChildrenOptions(valueSelect) {
        let updatedOptions = options
        /**
         * If the old selected option entry does not exist in options list
         */
        if( !(options && options.find((option) => option.value === valueInput))) {
            /**
             * retrieve the old selected option entry to re-insert into options list
             */
            const optionToRetrieve: IOptionType | undefined = optionsList.find((option) => option.value === valueInput)
            /**
             * re-insert retrieved option entry into options list
             */
            updatedOptions = options?.concat(optionToRetrieve ?? []) ?? []
            setAvailableChildrenOptions(updatedOptions.sort((opt1,opt2) => compareOptions(opt1,opt2)))
        }
        /**
         * remove the newly selected option entry from options list
         */
        updatedOptions && (updatedOptions = updatedOptions.filter((option) => option.value !== valueSelect))
        updatedOptions &&  setAvailableChildrenOptions(updatedOptions.sort())
    }

    const compareOptions = (option1:IOptionType,option2:IOptionType) => Number(option1.value) - Number(option2.value)

/*    const defaultValueFunction = (value) => {
        if (multiple) {
            return options?.filter(option => defaultValue?.includes(option.value))
        }
        return options?.filter(option => (option.value === value || (option.value === defaultValue && value === undefined)))
    };*/

    //options && (options = options.filter((option) => option.value === defaultValue))
    //defaultValue && setAvailableChildrenOptions())

/*
    const optionSelectedValues:(number|undefined)[]= orderElements.map(
        (orderedElement) => orderedElement && formData && Number(formData[orderedElement.id])
    );
    const initialOptionList:IOptionType[] = optionsList.filter(
        (option) =>   optionSelectedValues.includes(option.value)
    );
*/

    return (
        <div className={`${classes}  ${readonly && "readonly-element"}`}>
            <div className="form-select" title={rest.help}>
                <label htmlFor="select">{parse(label??'')}</label>
                <Controller
                    name={id}
                    options={options}
                    control={control}
                    rules={schema}
                    defaultValue={defaultValue}
                    render={({onChange, value}) => (
                        <Select
                            placeholder={t('common.select-placeholder')}
                            options={options}
                            isDisabled={readonly}
                            //defaultValue={defaultValueFunction(value)}
                            defaultValue={defaultValue}
                            isMulti={multiple}
                            isClearable={!required}
                            onChange={val => {
                                if (multiple) {
                                    onChange(val.map(object => object.value));
                                    handleChange(val);
                                    return false
                                }
                                onChange(val ? val.value : "");
                                handleChange(val ? val.value : "");
                            }}
                        />
                    )}
                />
                {error?.message && <FieldErrorMessage message={error.message}/>}
            </div>
        </div>
    )
};

export default OrderedSelectItem;
