import {FC} from "react";
import PdfFile from "./PdfFile";
import PngFile from "./PngFile";
import OtherFile from "./OtherFile";
import WordFile from "./WordFile";
import BmpFile from "./BmpFile";
import GifFile from "./GifFile";


interface IProps {
    filename: string|undefined
}

const MimeTypeFactory: FC<IProps> = ({filename}) => {
    const fields = filename?.split('.') ?? ['']
    const fileExt = fields[fields.length-1]
    switch (fileExt.toLocaleLowerCase()) {
        case 'pdf':
            return <PdfFile />
        case 'png':
            return <PngFile />
        case 'gif':
            return <GifFile />
        case 'bmp':
            return <BmpFile />
        case 'doc':
        case 'docx':
            return <WordFile />
        default:
            return <OtherFile />
    }
}

export default MimeTypeFactory
