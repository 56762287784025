import ReceiptDetailGatewayInterface from '../../domain/Receipt/ReceiptDetailGatewayInterface';
import ReceiptDetail from '../../domain/Receipt/ReceiptDetail';
import Caller from '../../domain/Api/Caller';
import envVariable from '../../presentation/util/envVariable';
import storage from '../../presentation/util/storage';
import SignedDocumentDetailGatewayInterface from '../../domain/Docusign/ReceiptDetailGatewayInterface';
import { SignedDocument } from '../../domain/Docusign/SignedDocument';
import SignedDocumentDetail from '../../domain/Docusign/SignedDocumentDetail';

export default class FetchSignedDocumentGateway implements SignedDocumentDetailGatewayInterface {

  async getByDocumentId(folderId: string, documentId: string): Promise<SignedDocumentDetail|null> {
    return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/${folderId}/docusign/documents/${documentId}`, {})
      .then(response => {
        if (!response) {
          return null
        }

        return new SignedDocumentDetail(response.document.id, response.document.resource,response.document.title, response.document.mimetype)
      }).catch(() => {
        return null
      })
  }

  async retrieveList(dossierId: string): Promise<SignedDocument[]|null> {
    return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossier/${dossierId}/docusign/documents`, {})
      .then(data => {
        if (!data) {
          return null
        }
        const documents: SignedDocument[] = []
        data.documents.map((doc) => {
          documents.push(new SignedDocument(doc.id, doc.name, doc.title ?? doc.name, doc.rank))
        })

        return documents
      }).catch(() => {
        return null
      })
  }

}
