import React, {FunctionComponent} from 'react';

const UnFold: FunctionComponent = () => {

  return (
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.46001 5.29002L5.00001 2.83002L2.53001 5.29002C2.14001 5.68002 1.51001 5.68002 1.12001 5.29002C0.730015 4.90002 0.730015 4.27002 1.12001 3.88002L4.29001 0.700017C4.47685 0.512765 4.7305 0.407532 4.99501 0.407532C5.25953 0.407532 5.51318 0.512765 5.70001 0.700017L8.87001 3.88002C9.26001 4.27002 9.26001 4.90002 8.87001 5.29002C8.48001 5.68002 7.85001 5.68002 7.46001 5.29002ZM2.54001 12.71L5.00001 15.17L7.47002 12.72C7.65685 12.5328 7.9105 12.4275 8.17501 12.4275C8.43953 12.4275 8.69318 12.5328 8.88001 12.72C9.27001 13.11 9.27001 13.74 8.88001 14.13L5.71001 17.3C5.32001 17.69 4.69001 17.69 4.30001 17.3L1.13001 14.12C0.740015 13.73 0.740015 13.1 1.13001 12.71C1.31685 12.5228 1.5705 12.4175 1.83501 12.4175C2.09953 12.4175 2.35318 12.5228 2.54001 12.71Z" fill="#616269"/>
    </svg>
  );
}

export default UnFold;
