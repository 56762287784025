import envVariable from '../../presentation/util/envVariable';
import Caller from '../../domain/Api/Caller';
import DocusignGatewayInterface from '../../domain/Docusign/DocusignGatewayInterface';
import DocusignUrl from '../../domain/Docusign/DocusignUrl';
import storage from '../../presentation/util/storage';

export default class FetchDocusignGateway implements DocusignGatewayInterface {

  async getUrl(docusignId: string): Promise<DocusignUrl|null> {

    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/docusigns/${docusignId}/iframe`, {})
      .then(data => {
        if (!data) {
          return null
        }

        return new DocusignUrl(data.docusign)
      })
      .catch(() => new DocusignUrl({'docusign': {'url': 'http://localhost:8080'}}));
  }
}




