
export default class BlockState {
  static complete = 'card--completed'
  static validate = 'card--validated'
  static disable = 'card--disabled'

  static getStateClass(state: string): string {
    switch (state) {
      case 'complete': {
        return this.complete
        break
      }
      case 'validate': {
        return this.validate
        break
      }
      case 'disable': {
        return this.disable
        break
      }
      default: {
        return ''
        break
      }
    }
  }
}
