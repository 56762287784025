import React from 'react'
import {useTranslation} from 'react-i18next'

import {PotentialCustomerInterface} from '../../../../domain/PotentialCustomer/PotentialCustomer'
import SelectedTickMark from '../../svg/SelectedTickMark';

interface Props {
  potentialCustomer: PotentialCustomerInterface;
  onSelectCustomer: (username: string) => void;
  selectedUsername: string|null;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

const MoralPersonLineTable: React.FC<Props> = ({potentialCustomer, onSelectCustomer, selectedUsername}) => {
  const {t} = useTranslation()

  const translationKey = potentialCustomer.birthCity ? 'validation.customer-info-recall-birth-date-with-city' : 'validation.customer-info-recall-birth-date-without-city'

  const handleButtonClick = () => {
    onSelectCustomer(potentialCustomer.username ?? '')
  }

  return (
    <>
      <td className="">
        {capitalizeFirstLetter(potentialCustomer.company ?? '')} ({capitalizeFirstLetter(potentialCustomer.legalForm ?? '')})<br/>
        {t('validation.represented-by')} {capitalizeFirstLetter(potentialCustomer.companyManager ?? '')}
      </td>
      <td className="">
        {(potentialCustomer.managerBirthDate || potentialCustomer.managerBirthCity) && t(translationKey, {
          date: potentialCustomer.managerBirthDate,
          city: potentialCustomer.managerBirthCity,
          country: potentialCustomer.managerBirthCountry,
        })}
      </td>
      <td className="">
        {potentialCustomer.email}
      </td>
      <td className={"form-control-center"}>
        { potentialCustomer?.username && selectedUsername === potentialCustomer?.username  ? <SelectedTickMark color={"var(--ink-1)" } /> : (
        <button type="button"
                className="button button-primary--outline button--medium form-control-center"
                onClick={handleButtonClick}>
          {t('validation.select-customer')}
        </button>
        )}
      </td>
    </>
  )

}

export default MoralPersonLineTable
