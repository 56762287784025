import Receipt from '../../domain/Receipt/Receipt';
import ReceiptDetailGatewayInterface from '../../domain/Receipt/ReceiptDetailGatewayInterface';
import ReceiptGatewayInterface from '../../domain/Receipt/ReceiptGatewayInterface';
import StoreGatewayInterface from '../../domain/Store/StoreGatewayInterface';

export default class RemoveReceiptUseCase
{
  public receiptDetailGateway;
  public receiptGateway;
  public storeGateway

  constructor(ReceiptDetailGateway: ReceiptDetailGatewayInterface,
              ReceiptGateway: ReceiptGatewayInterface,
              StoreGateway: StoreGatewayInterface) {
    this.receiptDetailGateway = ReceiptDetailGateway
    this.receiptGateway = ReceiptGateway
    this.storeGateway = StoreGateway
  }

  execute(receipt: Receipt): Promise<boolean> {
    return this.receiptDetailGateway.removeById(receipt.receiptId).then(response => {
      if (response) {
        const receipts = this.receiptGateway.find()
        const receiptsToSave = receipts.filter(receiptToSave => receiptToSave.receiptId !== receipt.receiptId)
        this.storeGateway.saveReceipts(receiptsToSave)
      }

      return response
    })
  }
}
