interface SignedDocumentInterface {
  id: string
  name: string
  title: string
  rank: number
}

class SignedDocument implements SignedDocumentInterface {
  constructor(
    public id: string,
    public name: string,
    public title: string,
    public rank: number
  ) {
    this.id = id
    this.name = name
    this.title = title
    this.rank = rank
  }
}

export type {SignedDocumentInterface}

export {
  SignedDocument
}
