import {FC, InputHTMLAttributes} from "react"
import {IOptionType} from "../select/IOptionType"
import ERadioType from "./ERadioType"
import {useAppDispatch, useAppSelector} from '../../../../store/hook'
import Element from '../../../../domain/Element/Element'
import SaveUseCase from '../../../../domain/Form/SaveUseCase'
import FormGateway from '../../../../gateway/Form/FormGateway'
import getElementsIdToRender from '../../../util/getElementsIdToRender'
import {updateElementTimestamp} from '../../../../store/element/elements'

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    option: IOptionType,
    checked: boolean,
    onChange: (value: string) => void,
    type: ERadioType,
    register?: () => void,
    name: string,
    disabled?: boolean
}

const RadioItem: FC<IProps> = ({
   option,
   onChange,
   type,
   register,
   name,
   disabled,
   checked,
}) => {
    const dispatch = useAppDispatch()

    const elementsWithCondition = JSON.parse(useAppSelector((state: { elements: { currentElementsWithCondition: string|null } }) => state.elements.currentElementsWithCondition)) as Element[]
    const elementsWithCalcule = JSON.parse(useAppSelector((state: { elements: { currentElementsWithCalcule: string|null } }) => state.elements.currentElementsWithCalcule)) as Element[]
    const elementsWithReference = JSON.parse(useAppSelector((state: { elements: { currentElementsWithReference: string|null } }) => state.elements.currentElementsWithReference)) as Element[]

    const handleChange = (event: React.MouseEvent<HTMLSpanElement>, value: string) => {
        event.preventDefault()
        event.stopPropagation()

        onChange(value)

        const data: Record<string, string> = { [name]: value }
        const saveUseCase = new SaveUseCase(new FormGateway())

        saveUseCase.execute(data).then(() => {
            const elementsIdToRender = getElementsIdToRender(elementsWithCondition, name, elementsWithCalcule, elementsWithReference)
            elementsIdToRender.forEach(elementIdToRender => dispatch(updateElementTimestamp({ id: elementIdToRender })))
        })
    }

    return (
      <div className={type === ERadioType.SIMPLE ? "col-md-4" : "col-md-6"}>
          <label htmlFor={`${name}_${option.value}`} className={type}>
              <input
                type="radio"
                readOnly
                checked={checked}
                ref={register}
                name={name}
                value={option.value}
                disabled={disabled}
                id={`${name}_${option.value}`}
              />
              <span onClick={(e) => handleChange(e, option.value)}>{option.label}</span>
          </label>
      </div>
    )
}

export default RadioItem
