import DocumentGatewayInterface from '../../domain/Document/DocumentGatewayInterface';
import Caller from "../../domain/Api/Caller";
import envVariable from "../../presentation/util/envVariable";
import storage from '../../presentation/util/storage';
import Receipt from '../../domain/Receipt/Receipt';
import GeneratedDocument from '../../domain/Document/GeneratedDocument';

export default class FetchDocumentGateway implements DocumentGatewayInterface {

  send(blockId: string, documents: Document[]): Promise<Receipt[]|null> {
    return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/receipts`, {
      submittingBlockId: blockId,
      receipts: documents
    })
      .then(data => {
        if (!data) {
          return null
        }

        const receipts: Receipt[] = []
        data.dossier.receipts.map(receipt => {
          receipts.push(new Receipt(receipt.receiptId, receipt.typeId, receipt.elementId, receipt.title, receipt.resource))
        })

        return receipts
      }).catch(() => {
        return null
      })
  }

  async getGeneratedDocuments(blockId: string): Promise<GeneratedDocument[]|null> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/documents/list`, {
      block_id: blockId
    })
      .then(data => {
        if (!data) {
          return null
        }

        const documents: GeneratedDocument[] = []
        data.documents.map((doc) => {
          documents.push(new GeneratedDocument(doc.id, doc.filename))
        })

        return documents
      })
  }

  async getGeneratedDocumentById(id: number): Promise<Blob|null> {
    return Caller.executePostBinaryFile(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/preview`, {
      documentId: id,
      save: false
    })
      .then(response => {
        if (!response.data) {
          return null
        }

        return response.data;
      })
  }

  async getGeneratedDocumentsAsArchive(blockId: string): Promise<Blob|null> {
    return Caller.executePostBinaryFile(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/documents/all`, {
      block_id: blockId
    })
      .then(response => {
        if (!response.data) {
          return null
        }
        return response.data;
      })
      .catch(() => {
        return null
      })
  }
}
