import envVariable from '../../presentation/util/envVariable';
import Caller from '../../domain/Api/Caller';
import PrintGatewayInterface from '../../domain/Print/PrintGatewayInterface';
import Print from '../../domain/Print/Print';
import storage from '../../presentation/util/storage';

export default class FetchPrintGateway implements PrintGatewayInterface {

  async getFile(printId: string): Promise<Print|null> {

    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/printPdf/${printId}`, {})
      .then(data => {
        if (!data) {
          return null
        }

        return new Print(data.print)
      })
      .catch(() => null);
  }
}




