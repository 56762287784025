import PageGatewayInterface from '../../domain/Page/PageGatewayInterface';
import Page from '../../domain/Page/Page';
import Caller from "../../domain/Api/Caller";
import storage from "../../presentation/util/storage";

export default class FetchPageGateway implements PageGatewayInterface {
  getPages(formId: string|null): Promise<Page[]> {
    return Caller.executeGet(`${Caller.initApiUrl()}/forms/${formId ?? storage.getFormId()}/pages`, {})
        .then(data => {
            if (!data) {
                return []
            }

            return (data.pages).map((page) => {
            return new Page(page.id,
                page.form_id,
                page.title,
                page.rank,
                page.link_description,
                page.slug)
            })
        })
  }
}
