import React, {InputHTMLAttributes, useCallback, useEffect, useState} from "react";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import useModal from "./useModal";
import Converter from "../../util/Converter";
import { downloadFile } from "../../util/DownloadFile";
import { IPreviewFile } from "./PreviewFile";

interface IPreviewModal {
    id: string,
    hide?: () => void;
    download: (key: string) => Promise<IPreviewFile|null>;
    className?: string;
}


const PreviewDocumentModal = ({ id, hide, download, className } :IPreviewModal) => {
    const {t} = useTranslation()
    const [isPreviewDocument, togglePreviewDocument] = useModal();
    const [loading, setLoading] = useState<boolean>(true);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalContent, setModalContent] = useState<InputHTMLAttributes<string>>();

    const handleHide = useCallback(() => {
        togglePreviewDocument()
        if (hide) {
            hide()
        }
    }, [])

    const downloadBinaryFile = useCallback((response) => {
        downloadFile({'title': response.filename}, response.resource)
    }, []);

    useEffect(
        () => {
            download(id).then((response) => {
                if (response) {
                    if (response.mimetype && response.mimetype !== 'application/octet-stream') {
                        const blobContent = new Converter().dataURItoBlob(response.resource, response.mimetype)
                        const url = URL.createObjectURL(blobContent);
                        setModalContent(
                            <div key={id} className="pj-modal-content-center container-iframe">
                                <iframe src={url} className="responsive-iframe" width={"100%"} height={"90%"}/>
                            </div>
                        )
                        setModalTitle(response.title ?? response.filename)
                    } else {
                        downloadBinaryFile(response)
                        handleHide()
                    }
                } else {
                    handleHide()
                }
            }).finally(() => setLoading(false))
        }, [id]
    )

    return (
        <Modal title={t('pj.modal-preview-title')}
            description={modalTitle}
            customClassName={className ?? `modal-xl`}
            hide={handleHide}
            loading={loading}>
            {modalContent}
        </Modal>
    )
}

export type { IPreviewFile }
export default PreviewDocumentModal;
