import {FC} from "react";
import ICheckboxProps from "./ICheckboxProps";
import FieldErrorMessage from "../messages/FieldErrorMessage";
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';


const Checkbox: FC<ICheckboxProps> = ({onChange, classes,error, disabled, register, checked, label, id, name, ...rest}) => {
  const dispatch = useAppDispatch()

  const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
  const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
  const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
  const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
  const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
  const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

  function handleChange(e) {
    if (onChange) {
      onChange(e)
    }

    const data = [];
    data[id] = e.target.checked
    const saveUseCase = new SaveUseCase(new FormGateway())
    saveUseCase.execute(data).then(() => {
      const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
      elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
    })
  }

    return (
        <div className={`${classes}  ${rest.readonly && "readonly-element"}`} title={rest.help}>

            <div className="checkbox-toggle__wrapper">
                <input className="checkbox no-skin checkbox-toggle" id={name} type="checkbox" onChange={handleChange} name={id}
                       disabled={disabled} ref={register} defaultChecked={checked} {...rest} />
                <label className="u-txt-size-l checkbox__label" htmlFor={name}>{label}</label>
            </div>

            {error?.message && <FieldErrorMessage message={error.message}/>}

        </div>

    )

};

export default Checkbox;
