import ReceiptDetail from '../../domain/Receipt/ReceiptDetail';
import ReceiptDetailGatewayInterface from '../../domain/Receipt/ReceiptDetailGatewayInterface';

export default class PreviewReceiptUseCase
{
  public receiptDetailGateway;

  constructor(ReceiptDetailGateway: ReceiptDetailGatewayInterface) {
    this.receiptDetailGateway = ReceiptDetailGateway;
  }

  execute(receiptId: string): Promise<ReceiptDetail|null> {
    return this.receiptDetailGateway.byReceiptId(receiptId).then(receiptDetail => {
      return receiptDetail
    })
  }
}
