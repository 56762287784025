import React, {FunctionComponent, useState, useEffect} from 'react'
import {FooterInterface} from '../../../domain/Footer/Footer'
import Retrieve from '../../../useCase/Contact/Retrieve'
import FetchFooterGateway from '../../../gateway/Contact/FetchFooterGateway'
import HTMLString from 'react-html-string'

const FooterComponent: FunctionComponent = () => {
  const [footer, setFooter] = useState<FooterInterface | null>(null)

  useEffect(() => {
    const retrieveContact = new Retrieve(new FetchFooterGateway())
    retrieveContact.execute().then(response => {
      if (response) {
        setFooter(response)
      }
    })
  }, [])

  return (
    <div className="main-footer">
      {footer && (
        <div className="main-footer__info">
          <div className="main-footer__info__person">
            {(footer.blocks.map((block, index) => {
              return <div
                className={`main-footer__info__block`}
                key={`footer_${index}`}
              >
                <HTMLString html={block.extraInfo ?? ''} />
              </div>
            }))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FooterComponent
