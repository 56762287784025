import CustomerInfoRecallGatewayInterface from '../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface'
import FolderGatewayInterface from '../../domain/Folder/FolderGatewayInterface'

export default class GetFolderPairingUseCase
{
  constructor(private readonly folderGateway: FolderGatewayInterface) {}

  async handle(dossierId: string): Promise<{[scope: string]: string}|null> {
    return this.folderGateway.getPairing(dossierId)
      .then((response) => {
        return response
      })
  }
}
