import React, {FunctionComponent} from 'react';


type Props = {
  color: string|undefined
}

const SelectedTickMark: FunctionComponent<Props> = ({color}) => {

  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 -0.5 17 17"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        <defs />
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <path
            d="M3.432,6.189 C3.824,5.798 4.455,5.798 4.847,6.189 L6.968,8.31 L13.147,2.131 C13.531,1.747 14.157,1.753 14.548,2.144 L16.67,4.266 C17.06,4.657 17.066,5.284 16.684,5.666 L7.662,14.687 C7.278,15.07 6.651,15.064 6.261,14.673 L1.311,9.723 C0.92,9.333 0.92,8.7 1.311,8.31 L3.432,6.189 Z"
            fill={color ?? "var(--ink-1)" }
          />
        </g>
      </g>
    </svg>
  );
}

export default SelectedTickMark;
