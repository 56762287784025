import * as React from 'react';
import {FC} from 'react';
import SelectCustom from '../component/elements/select/SelectCustom';
import RadiosList from '../component/elements/radios/RadiosList';
import ERadioType from '../component/elements/radios/ERadioType';
import Checkbox from '../component/elements/checkbox/Checkbox';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../component/button/Button';
import Input from '../component/elements/input/Input';
import MagicCheckbox from '../component/elements/checkbox/MagicCheckbox';
import Pin from '../component/pin/Pin';
import Notification from '../component/notification/Notification';
import {ENotificationType} from '../component/notification/ENotificationType';
import RangeSlider from '../component/elements/range-slider/RangeSlider';

interface IFormInput {
  firstname: string,
  lastname: string,
  casier: boolean,
  remember: boolean,
  country: string,
  nationality: string,
  domiciliation: string,
  work: string,
  patrimony: number
}

const schema = yup.object().shape({
  firstname: yup.string().required('Vous devez entrer votre prénom'),
  lastname : yup.string().required('Vous devez entrer votre nom').min(15, 'le prénom est trop court'),
  casier   : yup.boolean().oneOf([true], 'Cocher cette case')
});

/**
 * Page d'exemple d'utilisation des composants  et de react-hooks-form/yup
 */
const Example: FC = () => {

  const {register, clearErrors, handleSubmit, errors} = useForm({
    resolver: yupResolver(schema) //applique le schéma  de validation yup sur le hook useForm
  });

  const onSubmit = (data: IFormInput): void => {
    console.log(data);
  };

  const options = [
    {value: 'FRA', label: 'France', presentation: 'France'},
    {value: 'ITA', label: 'Italie', presentation: 'Italie'},
    {value: 'ESP', label: 'Espagne', presentation: 'Espagne'}
  ];

  return (
    <div>

      <Notification label={'Saisir ce formulaire'} type={ENotificationType.info} />

      <form className="form--grey-7 u-mbb" onSubmit={handleSubmit(onSubmit)}>

        <Input classes={'form-floating form-floating--icon account'}
               register={register}
               clearErrors={clearErrors}
               label={'Nom'} type={'text'} name={'firstname'}
               id={'firstname'} placeholder={'firstname'} />

        <Input classes={'form-floating form-floating--icon password'} label={'Prénom'}
               register={register} clearErrors={clearErrors} name={'lastname'} error={errors.lastname}
               type={'text'} id={'lastname'}
               placeholder={'lastname'} />

        <SelectCustom options={options} id="123" name={'country'} label={'Pays'} register={register} />

        <RadiosList options={options} id="123" type={ERadioType.RECTANGLE} name={'nationality'} label={'Nationalité'}
                    defaultValue={'FRA'} register={register} />
        <RadiosList options={options} id="123" type={ERadioType.SIMPLE} name={'domiciliation'} label={'Domiciliation'}
                    defaultValue={'FRA'} register={register} />
        <RadiosList options={options} id="123" type={ERadioType.CIRCLE} name={'work'} label={'Lieu de travail'}
                    defaultValue={'ITA'} register={register} />

        <Checkbox name={'casier'} label={'Casier judiciaire vierge'} register={register} id={'casier_id'}
                  error={errors.casier} />

        <MagicCheckbox name={'remember'} label={'Se souvenir de moi'} register={register} id={'remember_id'} />

        <RangeSlider min={0} max={100} start={5} label={'patrimoine'} name={'patrimony'} register={register} />

        <Button classes={'button-primary button--large u-mbb'} label={'Souscrire en ligne'}
                type={'submit'}>
        </Button>

      </form>

      <Pin description={'Voici un PIN'} />

    </div>
  );

};

export default Example;
