import ProspectGatewayInterface from '../../domain/Prospect/ProspectGatewayInterface';
import Caller from "../../domain/Api/Caller"

import envVariable from "../../presentation/util/envVariable"
import Client from '../../domain/Client/Client';
import Error from '../../domain/Error/Error';

export default class FetchProspectGateway implements ProspectGatewayInterface {
  async create(dossierId: string): Promise<Client | Error[]> {
    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/prospects`, {'dossierId': dossierId})
      .then(data => {

        if (data.errors) {
          return (data.errors).map((error) => {
            return new Error(error.property, error.message)
          })
        }

        return new Client(
          data.prospect.id,
          data.prospect.civility,
          data.prospect.first_name,
          data.prospect.last_name,
          data.prospect.username,
          data.prospect.email
        )
      })
  }
}
