import React, {FunctionComponent} from 'react';
import CopyIcon from "../../../assets/images/icons/copy.svg";

const CopyCustom: FunctionComponent = () => {

  return (
      <img src={CopyIcon} alt="Copy Icon" width="17" height="17"/>
  );

}
export default CopyCustom;
