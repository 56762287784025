import Element from "../Element";

export default class PercentageElementPreview {

    public static presentGroup(
        element: Element,
        submittedData: Record<string, any>,
        elements: Element[] | null
    ): string {
        let percentageGroupValue = 0;
        const percentageIds = element.attributes?.childrenElementsIds ?? [];
        const presentationString = 'empty' !== element.attributes?.presentation
            ? `${element.attributes?.presentation}`
            : '';
        const percentageGroupChildrenPresentation = percentageIds.map(
            function (percentageId) {
                const percentageValueEntered = (<number>submittedData[percentageId]);
                const percentageElement = elements?.find(anElement => anElement.id === percentageId);
                const childElementId = percentageElement ? percentageElement.attributes?.childElementId : null;
                const percentageDecisionValueEntered = childElementId ? submittedData[childElementId] : null;
                const percentageElementPresentation = percentageElement?.attributes?.presentation;
                if (percentageDecisionValueEntered || null === childElementId) {
                    percentageGroupValue += Number(percentageValueEntered);
                    return PercentageElementPreview.presentElement(
                        childElementId,
                        submittedData,
                        String(percentageValueEntered),
                        percentageElementPresentation
                    )
                }
                return null
            }
        );
        return `
            <div class="flex-container u-mtm">
                ${percentageGroupChildrenPresentation.join('')}
                <div class="col-md-12 b-top u-px0 u-mys">
                    <div class="flex-container">
                        <span class="col-xs-8">                        
                            <span class="u-txt-size-xl u-mrs">&#8658;</span>
                            <span>${presentationString}</span>                            
                        </span>
                        <span class="col-xs-4">
                            <span class="u-txt-size-xl u-mxs">&#9755;</span>
                            <span>
                                <strong>
                                    <u class="u-txt-color-ink-1">${String(percentageGroupValue)}%</u>
                                </strong>
                            </span>                        
                        </span>
                    </div>
                </div>
            </div>
        `;
    }

    public static presentElement(
        childElementId: string | null | undefined,
        submittedData: Record<string, any>,
        valueEntered: string,
        presentationString: string | undefined
    ): string | null {
        // eslint-disable-next-line no-case-declarations
        const percentageDecisionUniqueChoiceElementId = undefined !== childElementId && null !== childElementId
            ? childElementId
            : '';
        // eslint-disable-next-line no-case-declarations
        const percentageDecisionValueEntered =  submittedData[percentageDecisionUniqueChoiceElementId];
        if ('' !== percentageDecisionUniqueChoiceElementId && !percentageDecisionValueEntered) {
            return null
        }
        return `
                <div class="col-md-12 u-my0 u-px0 percentage-range-presentation">
                    <div class="row u-my0">
                        <span class="col-xs-8 u-my0">
                            <span class="arrow u-mxs">&#8625;</span>
                            <span>${presentationString}</span>
                        </span>
                        <span class="col-xs-4 u-my0">                                
                            <span class="u-txt-size-xl u-mxs">&#9758;</span>
                            <span><strong><u class="value">${valueEntered}%</u></strong></span>
                        </span>                                    
                    </div>
                </div>
        `;
    }

}
