import GeneratedDocumentInterface from './GeneratedDocumentInterface';

export default class GeneratedDocument implements GeneratedDocumentInterface {
    id: number
    filename: string

    constructor(id: number, filename: string) {
        this.id = id
        this.filename = filename
    }
}
