import DocumentInterface from './DocumentInterface';

export default class Document implements DocumentInterface {
    typeId: string
    elementId: string
    value: string
    title: string

    constructor(typeId: string, elementId: string, value: string, title: string) {
        this.typeId = typeId
        this.elementId = elementId
        this.value = value
        this.title = title
    }
}
