import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useForm} from "react-hook-form";
import Input from "../component/elements/input/Input";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Caller from "../../domain/Api/Caller";
import envVariable from "../util/envVariable";
import {useTranslation} from "react-i18next";

interface IFormInput { email: string; }

export default function Profile() {

    const {t} = useTranslation()

    const [message, setMessage] = useState({className: '', text: ''});
    const [profileData, setProfileData] = useState<{ firstname: string, lastname: string, username: string, email: string } | null>(null);
    const handleProfileData = async () => {
        return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/profile`, {})
    }
    const submitProfileData = async (data) => {
        return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/profile`, {
            emailSigner: data.email
        })
    }


    const history = useHistory();
    const redirectToHome = () =>{ 
        history.push(`${envVariable('REACT_APP_REDIRECT_URL')}`);
    }

    const onSubmit = async (data: IFormInput) => {
        submitProfileData(data)
            .then((data) =>{
                setProfileData(data.email)
                setMessage({className: 'button-success', text: t('account.data-saved')})
                sleep(1000).then(() => {
                    redirectToHome()
                });
            })
            .catch(() => setMessage({className: 'button-error', text: t('account.data-error')}))

    }
    const schema = yup.object().shape({
        //email: yup.string().required('Vous devez entrer votre email').min( 8, t('account.too-short') ),
    });

    const {register, clearErrors, handleSubmit, errors, setValue} = useForm({
        resolver: yupResolver(schema),
        defaultValues:{
            firstname:undefined,
            lastname: undefined,
            code_cgp:undefined,
            email:undefined
        }
    });

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    const fillCLasses = 'form-floating input-medium-width u-mxAuto'
    const input = [
        {
            label: t('profile.last-name'),
            errors: errors.lastname,
            classId: 'lastname',
            placeholder: t('profile.last-name'),
            disabledValue: true,
            register: register
        },
        {
            label: t('profile.first-name'),
            errors: errors.firstname,
            classId: 'firstname',
            placeholder: t('profile.first-name'),
            disabledValue: true,
            register: register
        },
        {
            label: t('profile.cgp-code'),
            errors: errors.firstname,
            classId: 'code_cgp',
            placeholder: t('profile.cgp-code'),
            disabledValue: true,
            register: register
        },
        {
            label: t('profile.email'),
            errors: errors.email,
            classId: 'email',
            placeholder: t('profile.email-help'),
            disabledValue: false,
            register: register
        }
    ]

    // Get data from DB when opening the page
    useEffect(() => {
        handleProfileData()
            .then((data) => {
                setValue('firstname', data.user?.firstname || '')
                setValue('lastname', data.user?.lastname || '')
                setValue('code_cgp', data.user?.username || '')
                setValue('email', data.user?.email || '')
            })
            .catch(() => setMessage({className: 'button-error', text: t('account.api-data-error')}))
    }, [])


    return (
        <div className='box-elevations box-elevations--base box-elevations--mb u-mxAuto u-mtxl'>
            <div className="flex-container center-md u-mtl">
                <div className="col-md-12">
                    <h2>{t('button.account')}</h2>
                </div>
            </div>
            <form className="form--grey-7 u-mbb" onSubmit={handleSubmit(onSubmit)}>


                {input.map(input =>
                    < Input id={input.classId}
                            name={input.classId}
                            classes={fillCLasses} label={input.label}
                            clearErrors={clearErrors} error={input.errors}
                            register={input?.register}
                            type={'text'}
                            placeholder={input.placeholder}
                            disabled={input.disabledValue}
                    />)
                }

                <div className="flex-container center-md u-mtl">
                    <div className="col-md-3">
                        <button className="button button-primary button--large u-mxAuto" onClick={redirectToHome}>Retour</button>
                    </div>
                    <div className="col-md-3">
                        <button type="submit" className="button button-primary button--large u-mxAuto">Modifier</button>
                    </div>
                </div>
                {message.text !== '' &&
                    <div className="flex-container center-md u-mtl">
                        <div className={`col-md-3button ${message.className}`}>{message.text}</div>
                    </div>
                }
            </form>
        </div>
    );
}