import React, {FunctionComponent, useState} from 'react';
import {Link} from 'react-router-dom';
import Logout from '../svg/Logout';
import Account from '../svg/Account';
import {useTranslation} from 'react-i18next';
import envVariable from "../../util/envVariable";

type Props = {
  reference?: number,
  cta?: boolean
};

const HeaderLightComponent: FunctionComponent<Props> = () => {
  const { t } = useTranslation()
  const role: string= localStorage.getItem('role') ?? '';
  const userProfileEnabled = envVariable('REACT_APP_USER_PROFILE_ENABLED');
  const logo = `${envVariable('REACT_APP_API_HOST')}/theme/images/logo.png`;
  return (
    <div className="main-header u-mxl">
      <div className="flex-container middle-xs no-compensation">
        <h1 className="u-mb0 u-mrl">
          <a href="#">
            <img src={logo} alt="Société d'investissement" style={{maxWidth: '150px'}}/>
          </a>
        </h1>
      </div>
      <div className="flex-container middle-xs center-xs"/>
      <div className="flex-container middle-xs center-xs">

      </div>
      <div className="flex-container middle-xs center-xs">
          {(role == 'partner' && userProfileEnabled == 'true') &&
              <Link to="/profile"
                    className="button button-secondary button--medium button--width-fit u-pxs">{t('button.account')}
                  &nbsp;<Account/></Link>
          }
          &nbsp;
        <div className="button__container button__container--logout">
          <Link to="/logout" className="button button-secondary button--medium button--logout u-pxs"><span className="text-logout">{t('button.logout')}</span> <Logout/></Link>
        </div>
      </div>
    </div>
  );
}

export default HeaderLightComponent;
