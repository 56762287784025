import React, {FunctionComponent} from 'react';
import envVariable from '../../util/envVariable'
const icon = envVariable('REACT_APP_ICON_ABANDONED')

type Props = {
  color: string
}

const AbandonedFiles: FunctionComponent<Props> = ({color}) => {
  if (icon === 'abandoned-1') {
    return (
      <svg id="a" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.15 65.15">
        <path
          d="M32.58,65.15C14.61,65.15,0,50.54,0,32.58S14.61,0,32.58,0s32.58,14.61,32.58,32.58-14.61,32.58-32.58,32.58ZM32.58,2.77C16.14,2.77,2.77,16.14,2.77,32.58s13.37,29.81,29.81,29.81,29.81-13.37,29.81-29.81S49.01,2.77,32.58,2.77Z"
          strokeWidth={0}
          fill={color}/>
        <path
          d="M52.17,48.79c-.38,0-.73-.15-1-.42L17.16,14.36c-.28-.28-.43-.68-.41-1.09.04-.42.24-.79.57-1.03,4.42-3.26,9.67-4.99,15.18-5.01.02,0,.04,0,.06,0,14.19,0,25.74,11.55,25.74,25.74,0,5.54-1.73,10.81-5.01,15.26-.24.33-.62.54-1.03.57-.01,0-.02,0-.03,0h-.06ZM51.97,45.21c2.32-3.65,3.54-7.87,3.54-12.23,0-12.64-10.28-22.93-22.93-22.93-4.38,0-8.59,1.22-12.24,3.53l31.63,31.63Z"
          strokeWidth={0}
          fill={color}/>
        <path
          d="M32.58,58.71c-14.19,0-25.74-11.55-25.74-25.74,0-5.54,1.73-10.81,5.01-15.26.24-.33.63-.54,1.03-.57,0,0,.1,0,.11,0,.36,0,.72.15.98.41l34.02,34.03c.28.28.43.68.41,1.09-.03.42-.24.79-.57,1.03-4.44,3.28-9.72,5.01-15.25,5.01ZM13.18,20.74c-2.31,3.65-3.53,7.87-3.53,12.23,0,12.62,10.25,22.89,22.86,22.93.02,0,.04,0,.06,0,4.38,0,8.59-1.22,12.23-3.53L13.18,20.74Z"
          strokeWidth={0}
          fill={color}/>
      </svg>
    )
  }

  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svg-abandoned-files-1" fillRule="evenodd" clipRule="evenodd"
            d="M48.54 23.6259L46.3739 21.4599L34.9999 32.8339L23.6267 21.4599L21.4598 23.6259L32.8339 35L21.4598 46.374L23.6267 48.54L34.9999 37.166L46.3739 48.54L48.54 46.374L37.166 35L48.54 23.6259Z"
            fill={color}/>
      <path className="svg-abandoned-files-2"
            d="M35 68C53.2254 68 68 53.2254 68 35C68 16.7746 53.2254 2 35 2C16.7746 2 2 16.7746 2 35C2 53.2254 16.7746 68 35 68Z"
            stroke={color} strokeWidth="2.2" strokeMiterlimit="10"/>
    </svg>
  );
}

export default AbandonedFiles;
