import {ViewElementInterface} from './ViewElement'

interface ViewBlockInterface {
  id: string;
  name: string;
  elements: ViewElementInterface[];
}

class ViewBlock {
  constructor(
    public id: string,
    public name: string,
    public elements: ViewElementInterface[],
  ) {
    this.id = id;
    this.name = name;
    this.elements = elements;
  }
}

export {
  ViewBlock
}

export type {
  ViewBlockInterface
}
