import ReceiptInterface from './ReceiptInterface';

export default class Receipt implements ReceiptInterface {
    receiptId: string
    typeId: string
    elementId: string
    title: string
    resource: string

    constructor(receiptId: string, typeId: string, elementId: string, title: string, resource: string) {
        this.receiptId = receiptId
        this.typeId = typeId
        this.elementId = elementId
        this.title = title
        this.resource = resource
    }
}
