import PageGatewayInterface from '../PageGatewayInterface';
import Page from '../Page';

export default class FindAllPagesUseCase
{
  public pageGatewayInterface;

  constructor(PageGatewayInterface: PageGatewayInterface) {
    this.pageGatewayInterface = PageGatewayInterface;
  }

  execute(): Promise<[]|Page[]> {
    return this.pageGatewayInterface.getPages()
  }
}
