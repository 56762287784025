interface PotentialCustomerInterface {
  civility: string | null
  firstName: string | null
  lastName: string | null
  username: string | null
  email: string | null
  birthDay: string | null
  birthCity: string | null
  birthCountry: string | null
  isMoralPerson: boolean
  company: string | null
  companyManager: string | null
  legalForm: string | null
  managerBirthDate: string | null
  managerBirthCity: string | null
  managerBirthCountry: string | null
}

class PotentialCustomer implements PotentialCustomerInterface {
  constructor(
    public civility: string | null,
    public firstName: string | null,
    public lastName: string | null,
    public username: string | null,
    public email: string | null,
    public birthDay: string | null,
    public birthCity: string | null,
    public birthCountry: string | null,
    public isMoralPerson: boolean,
    public company: string | null,
    public companyManager: string | null,
    public legalForm: string | null,
    public managerBirthDate: string | null,
    public managerBirthCity: string | null,
    public managerBirthCountry: string | null,
  ) {
    this.civility = civility
    this.firstName = firstName
    this.lastName = lastName
    this.username = username
    this.email = email
    this.birthDay = birthDay
    this.birthCity = birthCity
    this.birthCountry = birthCountry
    this.isMoralPerson = isMoralPerson
    this.company = company
    this.companyManager = companyManager
    this.legalForm = legalForm
    this.managerBirthDate = managerBirthDate
    this.managerBirthCity = managerBirthCity
    this.managerBirthCountry = managerBirthCountry
  }
}

export {
  PotentialCustomer
}

export type {
  PotentialCustomerInterface
}
