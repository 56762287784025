/* eslint-disable  @typescript-eslint/no-explicit-any */
import "@babel/polyfill";

import envVariable from "../../presentation/util/envVariable";
import Authentication from "../../oauth/Authentication";
import axios from 'axios';

export default class Caller {

    static initApiUrl(): string {
        return `${envVariable('REACT_APP_API_URL')}/${envVariable('REACT_APP_LOCALE')}`;
    }

    static async executeGet(
        path: string,
        params: Record<string, string>
    ): Promise<any> {
        let paramsString = '';

        if (Object.keys(params).length > 0) {
            paramsString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            paramsString = `?${paramsString}`;
        }

        return axios.get(`${path}${paramsString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${Authentication.getToken()}`
            },
        })
            .then(response => {
                return response.data;
            })
            .catch((error) => {
                if (401 == error.response?.status) {
                    return Authentication.refreshToken().then(success => {
                        if (success) {
                            return this.executeGet(path, params);
                        }
                    })
                }
                return Promise.reject(error)
            });
    }

    static async executeGetResponseAsBlob(
        path: string,
        params: Record<string, string>
    ): Promise<any> {
        let paramsString = '';

        if (Object.keys(params).length > 0) {
            paramsString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            paramsString = `?${paramsString}`;
        }

        return axios.get(`${path}${paramsString}`, {
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${Authentication.getToken()}`
            },
        })
            .then(response => {
                return response.data;
            })
            .catch(async (error) => {
                if (error.response) {
                    const contentType = error.response.headers['content-type'];
                    if (contentType && contentType.includes('application/json')) {
                        const errorBlob = error.response.data;
                        const errorText = await errorBlob.text();
                        const errorJson = JSON.parse(errorText);
                        return Promise.reject(errorJson);
                    }

                    if (401 == error.response?.status) {
                        const success = await Authentication.refreshToken()
                        if (success) {
                            return this.executeGet(path, params)
                        }
                    }
                }

                return Promise.reject(error)
            });
    }

    static async executePost(
      path: string,
      params: unknown
    ): Promise<any> {
        return axios(path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${Authentication.getToken()}`
            },
            data: JSON.stringify(params)
        })
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            if (401 == error.response?.status) {
                return Authentication.refreshToken().then(success => {
                    if (success) {
                        return this.executePost(path, params);
                    }
                })
            }
            return Promise.reject(error)
        });
    }

    static async executePostBinaryFile(
      path: string,
      params: unknown
    ): Promise<any> {
        return axios.request({
            url: path,
            responseType: 'blob',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${Authentication.getToken()}`
            },
            data: JSON.stringify(params)
        })
        .then(response => {
            return response;
        })
        .catch((error) => {
            if (401 == error.response?.status) {
                return Authentication.refreshToken().then(success => {
                    if (success) {
                        return this.executePostBinaryFile(path, params);
                    }
                })
            }
            return Promise.reject(error)
        });
}

    static async executePut(
      path: string,
      params: unknown
    ): Promise<any> {
        return axios(path, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': `${Authentication.getToken()}`
            },
            data: JSON.stringify(params)
        })
        .then(response => {
            return response.data;
        })
        .catch((error) => {
            if (401 == error.response?.status) {
                return Authentication.refreshToken().then(success => {
                    if (success) {
                        return this.executePut(path, params);
                    }
                })
            }
            return Promise.reject(error)
        });
    }

    static async executeDelete(
      path: string,
      params: unknown
    ): Promise<any> {
        return axios(path, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'Authorization': `${Authentication.getToken()}`
            },
            data: params
        })
        .then(response => {
            if (204 === response.status) {
                return true
            }

            return response.data;
        })
        .catch((error) => {
            if (401 == error.response?.status) {
                return Authentication.refreshToken().then(success => {
                    if (success) {
                        return this.executeDelete(path, params);
                    }
                })
            }
            return Promise.reject(error)
        });
    }
}
