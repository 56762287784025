import DocusignGatewayInterface from '../../domain/Docusign/DocusignGatewayInterface';

export default class GetUrl
{
  public docusignGateway;

  constructor(DocusignGateway: DocusignGatewayInterface) {
    this.docusignGateway = DocusignGateway;
  }

  async execute(docusignId: string): Promise<string> {
    return await this.docusignGateway.getUrl(docusignId).then(response => {
      try {
        return response.docusign.url
      } catch (error) {
        return ''
      }
    })
  }
}
