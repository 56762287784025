import {FC} from "react";
import parse from 'html-react-parser';

interface IProps {
    content?: string,
    classes?: string
}

const Text: FC<IProps> = ({content, classes}) => {
    return (
        <div className={classes}>
            {content ? parse(content) : ''}
        </div>
    )
}

export default Text;
