import React, {FunctionComponent, useEffect, useState} from 'react';

import Page from '../../../domain/Page/Page';
import WizardStepComponent from './WizardStepComponent';
import formatNumber from '../../util/formatNumber'

import {useAppDispatch, useAppSelector} from '../../../store/hook';
import {
  updateNextPageId,
  updateProgressValueByIdGoBack
} from '../../../store/wizzard/wizzardStep';
import {useHistory} from 'react-router-dom';

type Props = {
  pages: Page[],
  currentPageId: string,
};

const WizardComponent: FunctionComponent<Props> = ({currentPageId}) => {
  const history = useHistory();
  const dispatch = useAppDispatch()

  const storePages = useAppSelector(state =>  state.wizzardStep.pages)
  const [pages, setPages] = useState<Page[]|null>((typeof storePages === 'object') ? storePages : JSON.parse(storePages))
  const currentPageIndex = pages?.findIndex((p) => currentPageId === p.id)

  useEffect(() => {
    setPages((typeof storePages === 'object') ? storePages : JSON.parse(storePages));
  }, [storePages]);

  const blocksVisibleLength = useAppSelector(state =>  state.blocks.blocksVisibleLength)
  const storeWizardStepNextPageId = useAppSelector(state =>  state.wizzardStep.nextPageId)

  if (blocksVisibleLength === 0) {
    dispatch(updateNextPageId({'id': storeWizardStepNextPageId}))
    history.push(`/form/${storeWizardStepNextPageId}`)
  }

  const storeWizardStepCurrentPage = useAppSelector(state => state.wizzardStep.currentPageId)
  const storeIsBack = useAppSelector(state => state.wizzardStep.isBack)
  if (storeIsBack) {
    pages?.forEach(function (p) {
      if (p.id === storeWizardStepCurrentPage) {
        const progressPourcentage = (blocksVisibleLength === 1) ? 0 : ((blocksVisibleLength - 1) / blocksVisibleLength) * 100
        dispatch(updateProgressValueByIdGoBack({'id': storeWizardStepCurrentPage ,'progress': progressPourcentage}))
      }
    });
  }

  return (pages &&
    <div className="wizzard u-mxAuto">
      <div className="wizzard-inner">
        {pages.map((page, index) => (
          <WizardStepComponent key={index} page={page}
                               currentPageId={currentPageId}
                               number={formatNumber(index + 1, 2)}
                               progress={currentPageIndex && index < currentPageIndex ? 100 : page.progress} />
        ))}
      </div>
    </div>
  );
}

export default WizardComponent;
