import PrintInterface from './PrintInterface';

export default class Print implements PrintInterface{
  print: { file:string }

  constructor(print) {
    this.print = print
  }

}
