import {FC, useState, ChangeEvent} from 'react';
import {ISelectProps} from './ISelectProps';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import * as React from 'react';

const DataList: FC<ISelectProps> = ({options, id, defaultValue, classes, register, name, error, label, ...rest}) => {

  let defaultValuePresentation = ''
  if (options !== undefined) {
    const index = options.findIndex(option => option.value === defaultValue)

    if (-1 !== index) {
      defaultValuePresentation = (options[index].presentation)
    }
  }

  const [presentation, setPresentation] = useState(defaultValuePresentation);
  const [value, setValue] = useState(defaultValue);

  const validateInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (!options?.some(option => option.label === inputValue)) {
      setValue('');
    } else {
      const index = options?.findIndex(option => option.label === inputValue)
      setPresentation(inputValue)
      setValue(options[index].value)
    }
  };

  return (
    <div className={`${classes}  ${rest.readonly && "readonly-element"}`} title={rest.help}>
      <input className={'checkbox-toggle'} id={name} name={id} ref={register} value={value || defaultValue} readOnly />

      <label htmlFor={name}>{label}</label>
      <input list={`example-datalist-list-${name}`} defaultValue={presentation}
             onBlur={validateInputValue}
             className="form-control" />
      <datalist id={`example-datalist-list-${name}`}>
        {options?.map(option => {
          return <option key={option.value} value={option.label}
                         defaultChecked={option.value == value} />
        })}
      </datalist>

      {error?.message && <FieldErrorMessage message={error.message} />}

    </div>

  )
};

export default DataList;
