import React, {FunctionComponent} from 'react';

import '../../../assets/styles/components/_loader.scss'

type IProps = {
  className?: string
}

const Loader: FunctionComponent<IProps> = ({className}) => {
  return (
    <div className={className ?? "loader"}>Loading...</div>
  );
}

export default Loader;
