import PageInterface from './PageInterface'

export default class Page implements PageInterface {
  id: string
  form_id: string
  title: string
  rank: number
  link_description: string
  slug: string

  progress: number;

  constructor(
    id: string,
    form_id: string,
    title: string,
    rank: number,
    link_description: string,
    slug: string
  ) {
    this.id = id
    this.form_id = form_id
    this.title = title
    this.rank = rank
    this.link_description = link_description
    this.slug = slug
    this.progress = 0
  }
}
