import React, {FunctionComponent} from 'react';

type Props = {
  color: string
}

const SynchroRefused: FunctionComponent<Props> = ({color}) => {
  return (
    <svg width="70" height="70" viewBox="0 0 16.00 16.00" xmlns="http://www.w3.org/2000/svg" fill={color} stroke={color}
         strokeWidth="0.00016" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)">
      <g>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.468 3.687l-.757-.706a6 6 0 0 1 9.285 4.799L15.19 6.6l.75.76-2.09 2.07-.76-.01L11 7.31l.76-.76 1.236 1.25a5 5 0 0 0-7.528-4.113zm4.55 8.889l.784.73a6 6 0 0 1-8.796-5.04L.78 9.5 0 8.73l2.09-2.07.76.01 2.09 2.12-.76.76-1.167-1.18a5 5 0 0 0 7.005 4.206z"></path>
        <path d="M1.123 2.949l.682-.732L13.72 13.328l-.682.732z"></path>
      </g>
    </svg>
  );
}

export default SynchroRefused;
