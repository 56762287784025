import React from 'react'
import { useTranslation } from 'react-i18next'

import {ViewBlockInterface} from '../../../../domain/ViewDossier/ViewBlock'


interface Props {
  block: ViewBlockInterface,
}

const PresentationBlock: React.FC<Props> = ({block}) => {
  const {t} = useTranslation()

  const renderElementValue = (value: string|number|[]|boolean) => {
    if (Array.isArray(value)) {
      return (
        <ul className="list-reset">
          {value.map((item) => (
            <li key={item} className="u-mxs u-txt-size-s">- {item}</li>
          ))}
        </ul>
      )
    }

    if (typeof value === 'boolean') {
      return (
        <span className="u-txt-size-s">{value ? t('common.yes') : t('common.no')}</span>
      )
    }

    return <span className="u-txt-size-s">{value}</span>;
  };

  return (
    <div className="card card--validated card--validated--summary u-mbl">
      <div className="card__first">
        <h3 className="card__title">{block.name}</h3>
        <div className="flex-container">
          <div className="col-md-10">
            {block.elements.map((element) => {
              return (
                <div key={element.id}>
                  <span className="u-txt-bold u-txt-underline u-txt-size-s">{element.presentation}</span> : {renderElementValue(element.value)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationBlock
