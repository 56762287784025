import {FunctionComponent} from 'react';

interface Props {
  state: string,
}

const ValidationState: FunctionComponent<Props> = ({state}) => {
  return (
      <>
        {((state === 'validated' || state === 'sync') &&
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 1.33334C4.32016 1.33334 1.3335 4.32001 1.3335 8.00001C1.3335 11.68 4.32016 14.6667 8.00016 14.6667C11.6802 14.6667 14.6668 11.68 14.6668 8.00001C14.6668 4.32001 11.6802 1.33334 8.00016 1.33334ZM8.00016 13.3333C5.06016 13.3333 2.66683 10.94 2.66683 8.00001C2.66683 5.06001 5.06016 2.66668 8.00016 2.66668C10.9402 2.66668 13.3335 5.06001 13.3335 8.00001C13.3335 10.94 10.9402 13.3333 8.00016 13.3333ZM6.66684 9.44668L10.5868 5.52668C10.8468 5.26668 11.2735 5.26668 11.5335 5.52668C11.7935 5.78668 11.7935 6.20668 11.5335 6.46668L7.14017 10.86C6.88017 11.12 6.46017 11.12 6.20017 10.86L4.4735 9.13334C4.2135 8.87334 4.2135 8.45334 4.4735 8.19334C4.59806 8.06851 4.76716 7.99835 4.9435 7.99835C5.11985 7.99835 5.28895 8.06851 5.4135 8.19334L6.66684 9.44668Z" fill="#1BCC8C"/>
          </svg>
        )}
        {((state === 'error' || state === 'refused') &&
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00016 1.33334C4.3135 1.33334 1.3335 4.31334 1.3335 8.00001C1.3335 11.6867 4.3135 14.6667 8.00016 14.6667C11.6868 14.6667 14.6668 11.6867 14.6668 8.00001C14.6668 4.31334 11.6868 1.33334 8.00016 1.33334ZM9.26017 5.8L8.00017 7.06L6.74017 5.8C6.61562 5.67517 6.44652 5.60501 6.27017 5.60501C6.09383 5.60501 5.92473 5.67517 5.80017 5.8C5.54017 6.06 5.54017 6.48 5.80017 6.74L7.06017 8L5.80017 9.26C5.54017 9.52001 5.54017 9.94 5.80017 10.2C6.06017 10.46 6.48017 10.46 6.74017 10.2L8.00017 8.94L9.26017 10.2C9.52017 10.46 9.94017 10.46 10.2002 10.2C10.4602 9.94 10.4602 9.52001 10.2002 9.26L8.94017 8L10.2002 6.74C10.4602 6.48 10.4602 6.06 10.2002 5.8C9.94017 5.54667 9.51351 5.54667 9.26017 5.8ZM2.66683 8.00001C2.66683 10.94 5.06016 13.3333 8.00016 13.3333C10.9402 13.3333 13.3335 10.94 13.3335 8.00001C13.3335 5.06001 10.9402 2.66668 8.00016 2.66668C5.06016 2.66668 2.66683 5.06001 2.66683 8.00001Z" fill="#F25151"/>
          </svg>
        )}
      </>
  );

};

export default ValidationState;
