import FormGatewayInterface from './FormGatewayInterface';

export default class FindUseCase
{
    public formGatewayInterface;

    constructor(FormGatewayInterface: FormGatewayInterface) {
        this.formGatewayInterface = FormGatewayInterface;
    }

    execute(): Promise<null|Record<string, unknown>> {
        return this.formGatewayInterface.find()
    }
}
