import {FC, InputHTMLAttributes} from "react";

import {EIconType} from "../../../domain/Element/Icon/EIconType";

import Eye from "../svg/Eye";
import Download from "./Download";
import Passport from './Passport';
import Electricity from './Electricity';
import Other from './Other';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    type: EIconType|string|undefined
    color?: string|undefined
}

const IconFactory: FC<IProps> = ({type, color, ...props}) => {
    switch (type) {
        case EIconType.EYE:
            return <Eye color={color ?? "var(--ink-1)" } {...props}/>
        case EIconType.DOWNLOAD:
            return <Download color={color ?? "var(--ink-1)" } {...props}/>
        case EIconType.PASSPORT:
            return <Passport/>
        case EIconType.ELECTRICITY:
            return <Electricity/>
        case EIconType.OTHER:
            return <Other color={color}/>
        default:
            return <Other color={color}/>
    }
}

export default IconFactory
