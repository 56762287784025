interface IPreviewFile {
    resource: string
    filename: string
    title: string|null
    mimetype: string|null
}


class PreviewFile implements IPreviewFile {
    constructor(
        public resource: string,
        public filename: string,
        public mimetype: string|null = null,
        public title: string|null = null,
    ) {
        this.resource = resource
        this.filename = filename
        this.title = title
        this.mimetype = mimetype
    }
}
  
export type {IPreviewFile}

export {
    PreviewFile
}

