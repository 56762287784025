import ReceiptViewGatewayInterface from '../../domain/Receipt/ReceiptViewGatewayInterface'
import {ReceiptView} from '../../domain/Receipt/ReceiptView'
import Caller from '../../domain/Api/Caller'
import envVariable from '../../presentation/util/envVariable'

export default class ReceiptViewGateway implements ReceiptViewGatewayInterface {
  async retrieveList(dossierId: string): Promise<{ [key: string]: ReceiptView[] } | null> {
    const data = await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers/${dossierId}/receipts`, {})
    if (data) {
      return data
    }
    return null
  }
}
