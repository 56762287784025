import DatalistGatewayInterface from '../../domain/Options/DatalistGatewayInterface';

export default class GetChoicePresentation
{
  public datalist;

  constructor(DatalistGateway: DatalistGatewayInterface) {
    this.datalist = DatalistGateway;
  }

  async execute(datalistId: string, term: string): Promise<string> {
    return await this.datalist.getPresentation(datalistId, term).then(response => {
      try {
        return response.options[0].presentation
      } catch (error) {
        return ''
      }
    })
  }
}
