import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import '../../../assets/styles/components/_modal.scss';
import IconError from '../svg/IconError';

type IProps = {
  title?: string;
  message: string;
  onClose: () => void;
};

const ErrorModal: FunctionComponent<IProps> = ({ title, message, onClose }) => {
  Modal.setAppElement('#root');

  const { t } = useTranslation();

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      minWidth: "30%",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
    },
  };

  return (
    <>
      <Modal isOpen={true} style={styles}>
        <div className="content content-small flex-container">
          <div className="col-md-2 u-prl">
            <IconError/>
          </div>
          <div className="col-md-10 u-prl">
            {message}
          </div>
          <div className="content__buttons">
            <button
              type="button"
              className="button button-primary button--medium"
              onClick={onClose}
            >
              {t('modal.confirmation.close')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ErrorModal;
