import envVariable from '../../presentation/util/envVariable';
import Caller from '../../domain/Api/Caller';
import DatalistOptions from '../../domain/Options/DatalistOptions';
import DatalistGatewayInterface from '../../domain/Options/DatalistGatewayInterface';

export default class FetchDatalistGateway implements DatalistGatewayInterface {

  async getOptions(datalistId: string, term: string): Promise<DatalistOptions|null> {

    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/datalists/${datalistId}/options`, {filter: term})
      .then(data => {
        if (!data) {
          return null
        }

        return new DatalistOptions(
          data.options
        )
      })
      .catch(() => null);
  }

  async getPresentation(datalistId: string, value: string): Promise<DatalistOptions|null> {

    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/datalists/${datalistId}/values`, {filter: value})
      .then(data => {
        if (!data) {
          return null
        }

        return new DatalistOptions(
          data.options
        )
      })
      .catch(() => null);
  }
}




